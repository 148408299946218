import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDateTime: { input: any; output: any; }
  AWSJSON: { input: any; output: any; }
  AWSURL: { input: any; output: any; }
  AWSDate: { input: any; output: any; }
  AWSEmail: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  AWSIPAddress: { input: any; output: any; }
  AWSTime: { input: any; output: any; }
  AWSTimestamp: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  AdminCognitoGetUser: CognitoUser;
  AdminCognitoGetUserApprovalStatus?: Maybe<CognitoUserApprovalStatusEnum>;
  AdminCognitoListGroups?: Maybe<AdminCognitoListUserGroupsResult>;
  AdminCognitoListUserGroups?: Maybe<AdminCognitoListUserGroupsResult>;
  AdminCognitoListUsers: AdminCognitoListUsersResult;
  aircraft?: Maybe<MangoQueryResult>;
  boats?: Maybe<MangoQueryResult>;
  cargo?: Maybe<MangoQueryResult>;
  contracts?: Maybe<MangoQueryResult>;
  credentials?: Maybe<MangoQueryResult>;
  dashboardPassengers?: Maybe<MangoQueryResult>;
  DispatchFlights: MangoQueryResult;
  DispatchHistoryFlights: MangoQueryResult;
  employers?: Maybe<MangoQueryResult>;
  FlightDesigList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  flights?: Maybe<MangoQueryResult>;
  FlightTailList?: Maybe<Array<Maybe<Aircraft>>>;
  getAircraft?: Maybe<Aircraft>;
  getAircrafts?: Maybe<Array<Maybe<Aircraft>>>;
  GetAssetTrackingData: Scalars['AWSJSON']['output'];
  getBoat?: Maybe<BoatNode>;
  getBoatCrew?: Maybe<BoatCrew>;
  getBoats?: Maybe<Array<Maybe<BoatNode>>>;
  getCargo?: Maybe<ViewResult>;
  getCargoLeavingRig?: Maybe<Array<Maybe<DocksCargo>>>;
  getCargoNodeList?: Maybe<Array<Maybe<CargoNode>>>;
  getCargoToRig?: Maybe<Array<Maybe<DocksCargo>>>;
  getContract?: Maybe<Contract>;
  getContracts?: Maybe<Array<Maybe<Contract>>>;
  getContractsByCustomer?: Maybe<Array<Maybe<Contract>>>;
  getContractsByTransporter?: Maybe<Array<Maybe<Contract>>>;
  getCountryCodes?: Maybe<CountryCodes>;
  GetCovidVaxManufacturers: Array<Scalars['String']['output']>;
  getCustomer?: Maybe<Customer>;
  GetCustomerLocationDocs?: Maybe<Array<Maybe<PobNode>>>;
  getCustomers?: Maybe<Array<Maybe<Customer>>>;
  GetDispatchByID?: Maybe<Dispatch>;
  GetDispatchFlightHistory?: Maybe<Array<Dispatch>>;
  getDocksCargoOnRig?: Maybe<Array<Maybe<DocksCargo>>>;
  getEmployer: Employer;
  getEmployers?: Maybe<Array<Maybe<Employer>>>;
  getFinalizedFlights?: Maybe<MangoQueryResult>;
  getFlight?: Maybe<FlightNode>;
  getFlightHistory?: Maybe<HistoryFlightNode>;
  getFlights?: Maybe<Array<Maybe<FlightNode>>>;
  GetFuelTaken?: Maybe<Scalars['AWSJSON']['output']>;
  getIsnCredential?: Maybe<Array<Maybe<Credential>>>;
  getLocation?: Maybe<Location>;
  getLocations?: Maybe<Array<Maybe<Location>>>;
  getLocationsOffshore?: Maybe<Array<Maybe<Location>>>;
  getLocationsOnshore?: Maybe<Array<Maybe<Location>>>;
  getNonPreferredList?: Maybe<NonPreferred>;
  getOrganization?: Maybe<Organization>;
  getPassenger?: Maybe<PaxNode>;
  getPassengerIsnCheck?: Maybe<PaxNode>;
  getPassengers?: Maybe<Array<Maybe<PaxNode>>>;
  getPaxNodeByGroup?: Maybe<ScheduledPaxCgoResult>;
  getPaxNodeList?: Maybe<Array<Maybe<PaxNode>>>;
  getPaxNodesLeavingRig?: Maybe<Array<Maybe<PaxNode>>>;
  getPaxNodesToRig?: Maybe<Array<Maybe<PaxNode>>>;
  GetPersonCov19VaxRecords?: Maybe<Array<PersonCov19VaxRecord>>;
  getPersonList?: Maybe<Array<Maybe<Person>>>;
  getPersons?: Maybe<Array<Maybe<Person>>>;
  getPersonsByCred?: Maybe<Array<Maybe<Person>>>;
  getPersonSet?: Maybe<PersonSet>;
  getPersonSets?: Maybe<Array<Maybe<PersonSet>>>;
  getPilot?: Maybe<Pilot>;
  getPilots?: Maybe<Array<Maybe<Pilot>>>;
  getPobNode?: Maybe<PobNode>;
  getPobNodesOnRig?: Maybe<Array<Maybe<PobNode>>>;
  GetRemoteUserAppSettings: WebUserAppSettings;
  GetRemoteUserSettings: WebUserSettings;
  getRig?: Maybe<Rig>;
  getRigs?: Maybe<Array<Maybe<Rig>>>;
  getScheduledFlights?: Maybe<Array<Maybe<FlightNode>>>;
  getScheduledPassengers?: Maybe<Array<Maybe<PaxNode>>>;
  GetScheduledPaxCgoGroups?: Maybe<Array<Maybe<ScheduledPaxCgoGroupV2>>>;
  GetScheduledPaxCgoGroupsV2?: Maybe<Array<Maybe<ScheduledPaxCgoGroupV2>>>;
  GetScheduledPaxCgoV2?: Maybe<ScheduledPaxCgoResult>;
  getScheduledPaxInbCgo?: Maybe<ScheduledPaxCgoResult>;
  getScheduledPaxInbCgoGroups?: Maybe<Array<Maybe<ScheduledPaxNodeGroup>>>;
  getScheduledPaxNodeGroups?: Maybe<Array<Maybe<ScheduledPaxNodeGroup>>>;
  GetScheduleHistory?: Maybe<ScheduleHistoryDetail>;
  getTransporter?: Maybe<Transporter>;
  getTSAPassengers?: Maybe<Array<Maybe<PaxNode>>>;
  getUnassignedPassenger?: Maybe<Array<Maybe<PaxNode>>>;
  GetUserOrganization?: Maybe<OrganizationTypes>;
  getWhiteList?: Maybe<WhiteList>;
  ISNQuickCheck?: Maybe<IsnResultWithHeader>;
  ListAvailableNotifiers: ListAvailableNotifiersResponse;
  ListScheduleHistory: Array<ScheduleHistoryListItem>;
  LiveCargo?: Maybe<Array<Maybe<CargoNode>>>;
  LiveFlight?: Maybe<Array<Maybe<FlightNode>>>;
  LivePassenger?: Maybe<Array<Maybe<PaxNode>>>;
  locations?: Maybe<MangoQueryResult>;
  MasterDataAircrafts: MangoQueryResult;
  MasterDataContracts: MangoQueryResult;
  MasterDataCustomers: MangoQueryResult;
  MasterDataEmployers: MangoQueryResult;
  MasterDataLocations: MangoQueryResult;
  MasterDataPersonnel: MangoQueryResult;
  MasterDataPilots: MangoQueryResult;
  MasterDataVessels: MangoQueryResult;
  NodeDepartureList?: Maybe<Array<Maybe<Location>>>;
  NodeDestinationList?: Maybe<Array<Maybe<Location>>>;
  organizations?: Maybe<MangoQueryResult>;
  passengers?: Maybe<MangoQueryResult>;
  PaxCustomerList?: Maybe<Array<Maybe<Customer>>>;
  PaxEmployerList?: Maybe<Array<Maybe<Employer>>>;
  person?: Maybe<Person>;
  personCredentials?: Maybe<Array<Maybe<Credential>>>;
  persons?: Maybe<MangoQueryResult>;
  personSearch?: Maybe<Array<Maybe<Person>>>;
  pilots?: Maybe<MangoQueryResult>;
  report?: Maybe<Report>;
  reportCargo?: Maybe<Array<Maybe<CargoNode>>>;
  reportFlights?: Maybe<MangoQueryResult>;
  reportIsn?: Maybe<Array<Maybe<PaxNode>>>;
  reportPassenger?: Maybe<Array<Maybe<PaxNode>>>;
  reports?: Maybe<MangoQueryResult>;
  resolve_entity_ids?: Maybe<Array<Maybe<Entity>>>;
  run_quick_report?: Maybe<Report>;
  searchEmployers?: Maybe<MangoQueryResult>;
  searchLocations?: Maybe<MangoQueryResult>;
  UniqueListTest?: Maybe<Array<Maybe<Customer>>>;
  vessels?: Maybe<MangoQueryResult>;
};


export type QueryAdminCognitoGetUserArgs = {
  Username: Scalars['String']['input'];
};


export type QueryAdminCognitoGetUserApprovalStatusArgs = {
  Username: Scalars['String']['input'];
};


export type QueryAdminCognitoListGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdminCognitoListUserGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};


export type QueryAdminCognitoListUsersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAircraftArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AircraftFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBoatsArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<BoatsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCargoArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CargoFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryContractsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContractsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCredentialsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CredentialFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDashboardPassengersArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['AWSDateTime']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['AWSDateTime']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryDispatchHistoryFlightsArgs = {
  aircraftID?: InputMaybe<Scalars['ID']['input']>;
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  dateEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  dateStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  flightName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  scheduledFlightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryEmployersArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EmployerFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFlightDesigListArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryFlightsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FlightsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFlightTailListArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetAircraftArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetAircraftsArgs = {
  tpID: Scalars['ID']['input'];
};


export type QueryGetBoatArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetBoatCrewArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetBoatsArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetCargoArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetCargoLeavingRigArgs = {
  departureID: Scalars['ID']['input'];
};


export type QueryGetCargoNodeListArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryGetCargoToRigArgs = {
  destinationID: Scalars['ID']['input'];
};


export type QueryGetContractArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetContractsArgs = {
  locationID?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  orgType: Scalars['String']['input'];
  tpID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetContractsByCustomerArgs = {
  customerID: Scalars['ID']['input'];
};


export type QueryGetContractsByTransporterArgs = {
  tpID: Scalars['ID']['input'];
};


export type QueryGetCustomerArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetCustomerLocationDocsArgs = {
  customerID: Scalars['String']['input'];
};


export type QueryGetDispatchByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDispatchFlightHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDocksCargoOnRigArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetEmployerArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetFinalizedFlightsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['AWSDate']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetFlightArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetFlightHistoryArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetFlightsArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetFuelTakenArgs = {
  endDateTime?: InputMaybe<Scalars['String']['input']>;
  reportType: Scalars['String']['input'];
  startDateTime: Scalars['String']['input'];
  tpID: Scalars['String']['input'];
};


export type QueryGetIsnCredentialArgs = {
  personIDs: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryGetLocationArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetLocationsArgs = {
  includeOneOff?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetNonPreferredListArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetOrganizationArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetPassengerArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetPassengerIsnCheckArgs = {
  _id: Scalars['ID']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetPassengersArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetPaxNodeByGroupArgs = {
  customerID: Scalars['ID']['input'];
  lastKnownController?: InputMaybe<Scalars['ID']['input']>;
  scheduledFlightDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetPaxNodeListArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryGetPaxNodesLeavingRigArgs = {
  departureID: Scalars['ID']['input'];
};


export type QueryGetPaxNodesToRigArgs = {
  destinationID: Scalars['ID']['input'];
};


export type QueryGetPersonCov19VaxRecordsArgs = {
  descending?: InputMaybe<Scalars['Boolean']['input']>;
  personID: Scalars['ID']['input'];
};


export type QueryGetPersonListArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryGetPersonsArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetPersonsByCredArgs = {
  search: Scalars['String']['input'];
};


export type QueryGetPersonSetArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetPersonSetsArgs = {
  customerID: Scalars['ID']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryGetPilotArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetPilotsArgs = {
  tpID: Scalars['ID']['input'];
};


export type QueryGetPobNodeArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetPobNodesOnRigArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetRemoteUserAppSettingsArgs = {
  appName: Scalars['String']['input'];
};


export type QueryGetRigArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetRigsArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetScheduledFlightsArgs = {
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPassengersArgs = {
  customerID: Scalars['ID']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  scheduledFlightDate: Scalars['AWSDate']['input'];
  scheduledGroup: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPaxCgoGroupsArgs = {
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  groupByLeg?: InputMaybe<Scalars['Boolean']['input']>;
  noCurrentCarrier?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPaxCgoGroupsV2Args = {
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  groupByLeg?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPaxCgoV2Args = {
  customerID: Scalars['ID']['input'];
  lastKnownController?: InputMaybe<Scalars['ID']['input']>;
  scheduledFlightDate: Scalars['AWSDate']['input'];
  scheduledGroup: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPaxInbCgoArgs = {
  customerID: Scalars['ID']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  scheduledFlightDate: Scalars['AWSDate']['input'];
  scheduledGroup: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPaxInbCgoGroupsArgs = {
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduledPaxNodeGroupsArgs = {
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetScheduleHistoryArgs = {
  date: Scalars['AWSDate']['input'];
  groupName: Scalars['String']['input'];
  lastKnownController: Scalars['ID']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetTransporterArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryGetTsaPassengersArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  scheduledFlightDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryGetUnassignedPassengerArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  endCheckedInTime: Scalars['AWSDateTime']['input'];
  startCheckedInTime: Scalars['AWSDateTime']['input'];
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<TransitType>;
};


export type QueryGetWhiteListArgs = {
  _id: Scalars['ID']['input'];
};


export type QueryIsnQuickCheckArgs = {
  customerID: Scalars['ID']['input'];
  isnNumber: Scalars['String']['input'];
};


export type QueryListAvailableNotifiersArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListScheduleHistoryArgs = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['AWSDate']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryLiveCargoArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  flightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLiveFlightArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  flightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLivePassengerArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  flightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLocationsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LocationsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataAircraftsArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataContractsArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  currDate?: InputMaybe<Scalars['AWSDate']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataCustomersArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataEmployersArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataLocationsArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  hasDataflytHardware?: InputMaybe<Scalars['Boolean']['input']>;
  isOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  twicRequired?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMasterDataPersonnelArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataPilotsArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMasterDataVesselsArgs = {
  bookmark?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Array<FilterByKeyValueInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeDepartureListArgs = {
  classType: Scalars['String']['input'];
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryNodeDestinationListArgs = {
  classType: Scalars['String']['input'];
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPassengersArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PassengersFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaxCustomerListArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryPaxEmployerListArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPersonCredentialsArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryPersonsArgs = {
  advancedFilter?: InputMaybe<Array<FilterByKeyValueInput>>;
  advancedFilterOr?: InputMaybe<Array<FilterByKeyValueInput>>;
  bookmark?: InputMaybe<Scalars['String']['input']>;
  excludeNonPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PersonFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Array<FilterByKeyValueInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPersonSearchArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPilotsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PilotsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReportArgs = {
  id: Scalars['ID']['input'];
  include_docs?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryReportCargoArgs = {
  customerID: Scalars['ID']['input'];
  departureID?: InputMaybe<Scalars['ID']['input']>;
  destinationID?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['AWSDate']['input'];
  finalized?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReportFlightsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  desig?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['AWSDate']['input'];
  finalized: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryReportIsnArgs = {
  customerID: Scalars['ID']['input'];
  employerID?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['AWSDate']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryReportPassengerArgs = {
  customerID: Scalars['ID']['input'];
  departureID?: InputMaybe<Scalars['ID']['input']>;
  destinationID?: InputMaybe<Scalars['ID']['input']>;
  employerID?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['AWSDateTime']['input'];
  finalized?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['AWSDateTime']['input'];
  tpID: Scalars['ID']['input'];
};


export type QueryReportsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryResolve_Entity_IdsArgs = {
  db?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  typename: Scalars['String']['input'];
};


export type QueryRun_Quick_ReportArgs = {
  criteria: Array<ReportCriteriaInput>;
  name: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
  type: ReportType;
};


export type QuerySearchEmployersArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchLocationsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUniqueListTestArgs = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type QueryVesselsArgs = {
  bookmark?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VesselsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CognitoUser = {
  __typename?: 'CognitoUser';
  approvalStatus?: Maybe<CognitoUserApprovalStatusEnum>;
  Attributes?: Maybe<Array<CognitoUserAttribute>>;
  Enabled?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<CognitoUserGroup>>;
  mappedAttributes?: Maybe<DfCognitoUserMappedAtrributes>;
  UserCreateDate?: Maybe<Scalars['String']['output']>;
  UserLastModifiedDate?: Maybe<Scalars['String']['output']>;
  Username?: Maybe<Scalars['String']['output']>;
  userSettings: WebUserSettings;
  UserStatus?: Maybe<Scalars['String']['output']>;
};

export enum CognitoUserApprovalStatusEnum {
  approved = 'approved',
  awaiting_approval = 'awaiting_approval',
  denied = 'denied'
}

export type CognitoUserAttribute = {
  __typename?: 'CognitoUserAttribute';
  Name?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

export type CognitoUserGroup = {
  __typename?: 'CognitoUserGroup';
  CreationDate?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastModifiedDate?: Maybe<Scalars['String']['output']>;
};

export type DfCognitoUserMappedAtrributes = {
  __typename?: 'DFCognitoUserMappedAtrributes';
  company_phone_number?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  family_name?: Maybe<Scalars['String']['output']>;
  given_name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  organizationClassType?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  phone_number_verified?: Maybe<Scalars['Boolean']['output']>;
  sub?: Maybe<Scalars['String']['output']>;
};

export type WebUserSettings = Entity & {
  __typename?: 'WebUserSettings';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  apps?: Maybe<Array<WebUserAppSettings>>;
  classType?: Maybe<Scalars['String']['output']>;
  cognitoUsername?: Maybe<Scalars['String']['output']>;
  contractDocs?: Maybe<Array<Maybe<Contract>>>;
  contracts?: Maybe<Array<Scalars['ID']['output']>>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Entity = {
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type WebUserAppSettings = {
  __typename?: 'WebUserAppSettings';
  appName: Scalars['String']['output'];
  settings?: Maybe<Scalars['AWSJSON']['output']>;
};

export type Contract = Entity & {
  __typename?: 'Contract';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID: Organization;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  locationID?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['AWSDate']['output']>;
  tpID: Transporter;
};

export type Organization = OrganizationInterface & {
  __typename?: 'Organization';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type OrganizationInterface = {
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type ManifestPrintoutType = {
  __typename?: 'ManifestPrintoutType';
  layouts?: Maybe<Array<Maybe<ManifestPrintoutLayout>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ManifestPrintoutLayout = {
  __typename?: 'ManifestPrintoutLayout';
  logo?: Maybe<Scalars['AWSURL']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum OrganizationType {
  boat = 'boat',
  flight = 'flight',
  pob = 'pob'
}

export type Location = Entity & {
  __typename?: 'Location';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  areaBlockName?: Maybe<Scalars['String']['output']>;
  block?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  getRig?: Maybe<Rig>;
  hasDataflytHardware?: Maybe<Scalars['Boolean']['output']>;
  isOneOff?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerOrg?: Maybe<Scalars['String']['output']>;
  resolvedCoordinates?: Maybe<LocationCoordinates>;
  twicRequired?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<LocationType>;
};

export type Rig = Entity & {
  __typename?: 'Rig';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  bedQuartersIds?: Maybe<Array<Scalars['String']['output']>>;
  bldgQuartersIds?: Maybe<Array<Scalars['String']['output']>>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<Customer>;
  deletedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  essentialPersonIdList?: Maybe<Array<Scalars['String']['output']>>;
  locationID?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  musterLastPrintedOnMap?: Maybe<Scalars['AWSJSON']['output']>;
  musterLocNames?: Maybe<Array<Scalars['String']['output']>>;
  musterRequiresReprint?: Maybe<Scalars['AWSJSON']['output']>;
  personIdToMusterStationMap?: Maybe<Scalars['AWSJSON']['output']>;
  sectQuartersIds?: Maybe<Array<Scalars['String']['output']>>;
  tpID?: Maybe<Transporter>;
};

export type Customer = Entity & OrganizationInterface & {
  __typename?: 'Customer';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  askHaveYouBeenVaccinatedQuestion?: Maybe<Scalars['Boolean']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  ebsAirCanisterRequired?: Maybe<Scalars['Boolean']['output']>;
  employerEvaluationPercentage?: Maybe<Array<Maybe<EmployerEvaluationPercentage>>>;
  extraBroadCheckRequired?: Maybe<Scalars['Boolean']['output']>;
  illnessCheckRequired?: Maybe<Scalars['Boolean']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notificationEmailList?: Maybe<Array<OrgEmailListItem>>;
  quarantinedCheckRequired?: Maybe<Scalars['Boolean']['output']>;
  quarantinedCountries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  quarantineTravelPeriod?: Maybe<Scalars['Int']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  steelToeIDDestinations?: Maybe<Array<Maybe<Location>>>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type EmployerEvaluationPercentage = {
  __typename?: 'EmployerEvaluationPercentage';
  employerID: Employer;
  percentage?: Maybe<Scalars['Int']['output']>;
};

export type Employer = Entity & OrganizationInterface & {
  __typename?: 'Employer';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notificationEmailList?: Maybe<Array<OrgEmailListItem>>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type OrgEmailListItem = {
  __typename?: 'OrgEmailListItem';
  email: Scalars['String']['output'];
  options?: Maybe<OrgEmailListItemOptions>;
};

export type OrgEmailListItemOptions = {
  __typename?: 'OrgEmailListItemOptions';
  optIn?: Maybe<Array<Scalars['String']['output']>>;
  optInAll?: Maybe<Scalars['Boolean']['output']>;
};

export type Transporter = Entity & OrganizationInterface & {
  __typename?: 'Transporter';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  tpID?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type LocationCoordinates = {
  __typename?: 'LocationCoordinates';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export enum LocationType {
  OFFSHORE = 'OFFSHORE',
  ONSHORE = 'ONSHORE'
}

export type AdminCognitoListUserGroupsResult = {
  __typename?: 'AdminCognitoListUserGroupsResult';
  groups: Array<CognitoUserGroup>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AdminCognitoListUsersResult = {
  __typename?: 'AdminCognitoListUsersResult';
  paginationToken?: Maybe<Scalars['String']['output']>;
  users: Array<CognitoUser>;
};

export type AircraftFilterInput = {
  model?: InputMaybe<Scalars['String']['input']>;
  tailNum?: InputMaybe<Scalars['String']['input']>;
};

export type MangoQueryResult = {
  __typename?: 'MangoQueryResult';
  bookmark?: Maybe<Scalars['String']['output']>;
  docs?: Maybe<Array<Maybe<Entity>>>;
  execution_stats?: Maybe<MangoExecutionStats>;
  warning?: Maybe<Scalars['String']['output']>;
  warningCode?: Maybe<Scalars['String']['output']>;
};

export type MangoExecutionStats = {
  __typename?: 'MangoExecutionStats';
  execution_time_ms?: Maybe<Scalars['Float']['output']>;
  results_returned?: Maybe<Scalars['Int']['output']>;
  total_docs_examined?: Maybe<Scalars['Int']['output']>;
  total_keys_examined?: Maybe<Scalars['Int']['output']>;
  total_quorum_docs_examined?: Maybe<Scalars['Int']['output']>;
};

export type BoatsFilterInput = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  desig?: InputMaybe<Scalars['String']['input']>;
  finalized?: InputMaybe<Scalars['Boolean']['input']>;
  tpID?: InputMaybe<Scalars['ID']['input']>;
};

export type CargoFilterInput = {
  cargo?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  checkInTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  departures?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  destinations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  employers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  flights?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groundPickup?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nodeStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onlyShowLive?: InputMaybe<Scalars['Boolean']['input']>;
  onlyUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  transitTypes?: InputMaybe<Array<InputMaybe<TransitType>>>;
  transporter?: InputMaybe<Scalars['ID']['input']>;
};

export enum TransitType {
  INBOUND = 'INBOUND',
  NONE = 'NONE',
  OUTBOUND = 'OUTBOUND',
  TRANSFER = 'TRANSFER'
}

export type ContractsFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  filterByDate?: InputMaybe<Scalars['AWSDate']['input']>;
  locationID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tpID?: InputMaybe<Scalars['ID']['input']>;
};

export type CredentialFilterInput = {
  credentialIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type EmployerFilterInput = {
  employers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FlightsFilterInput = {
  customers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  departures?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  desig?: InputMaybe<Scalars['String']['input']>;
  destinations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  flights?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  onlyShowLive?: InputMaybe<Scalars['Boolean']['input']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transporter?: InputMaybe<Scalars['ID']['input']>;
};

export type Aircraft = Entity & {
  __typename?: 'Aircraft';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  maxLoad?: Maybe<Scalars['Int']['output']>;
  maxSeats?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  tailNum?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Transporter>;
};

export type BoatNode = Entity & {
  __typename?: 'BoatNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  boatCrewIDs?: Maybe<Array<Maybe<BoatCrew>>>;
  cgoIDList?: Maybe<Array<Maybe<CargoNode>>>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<Customer>;
  departureIDs?: Maybe<Array<Maybe<Location>>>;
  desig?: Maybe<Scalars['String']['output']>;
  destinationIDs?: Maybe<Array<Maybe<Location>>>;
  dockState?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  paxIDList?: Maybe<Array<Maybe<PaxNode>>>;
  state?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Transporter>;
  vesselID?: Maybe<Vessel>;
};

export type BoatCrew = {
  __typename?: 'BoatCrew';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  authorization?: Maybe<Authorization>;
  classType?: Maybe<Scalars['String']['output']>;
  contactInfo?: Maybe<ContactInfo>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<Customer>;
  isnNumber?: Maybe<Scalars['String']['output']>;
  lastIsnResultWithHeader?: Maybe<IsnResultWithHeader>;
  locationID?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  tpID?: Maybe<Transporter>;
};

export type Authorization = {
  __typename?: 'Authorization';
  brdRestriction_EbsCard?: Maybe<BrdRestriction>;
  brdRestriction_Hazmat?: Maybe<BrdRestriction>;
  brdRestriction_HuetCard?: Maybe<BrdRestriction>;
  brdRestriction_Illness?: Maybe<BrdRestriction>;
  brdRestriction_Isn?: Maybe<BrdRestriction>;
  brdRestriction_NoFlyListed?: Maybe<BrdRestriction>;
  brdRestriction_NonPreferred?: Maybe<BrdRestriction>;
  brdRestriction_Quarantined?: Maybe<BrdRestriction>;
  brdRestriction_SafetyCard?: Maybe<BrdRestriction>;
  brdRestriction_SteelToes?: Maybe<BrdRestriction>;
  brdRestriction_Twic?: Maybe<BrdRestriction>;
  hasEbsCard?: Maybe<Scalars['Boolean']['output']>;
  hasHuetCard?: Maybe<Scalars['Boolean']['output']>;
  hasIllness?: Maybe<Scalars['Boolean']['output']>;
  hasQuarantined?: Maybe<Scalars['Boolean']['output']>;
  hasSafetyCard?: Maybe<Scalars['Boolean']['output']>;
  hasSteelToe?: Maybe<Scalars['Boolean']['output']>;
  hasTwicReq?: Maybe<Scalars['Boolean']['output']>;
  noFlyListed?: Maybe<Scalars['Boolean']['output']>;
  onBlackList?: Maybe<Scalars['Boolean']['output']>;
  onWhiteList?: Maybe<Scalars['Boolean']['output']>;
  validIsnResult?: Maybe<Scalars['Boolean']['output']>;
};

export type BrdRestriction = {
  __typename?: 'BrdRestriction';
  approverFirstName?: Maybe<Scalars['String']['output']>;
  approverLastName?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  overriderFirstName?: Maybe<Scalars['String']['output']>;
  overriderLastName?: Maybe<Scalars['String']['output']>;
  overriderReason?: Maybe<Scalars['String']['output']>;
  overriderTitle?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  redWhiteOverride?: Maybe<Scalars['String']['output']>;
  restrictionType: BrdRestrictionRestrictionType;
  status: BrdRestrictionStatus;
  type?: Maybe<BrdRestrictionType>;
};

export enum BrdRestrictionRestrictionType {
  HAZMAT = 'HAZMAT',
  HUET_CARD = 'HUET_CARD',
  ISN = 'ISN',
  NOFLY = 'NOFLY',
  NON_PREFERED = 'NON_PREFERED',
  SAFTEY_CARD = 'SAFTEY_CARD',
  STEEL_TOED_BOOTS = 'STEEL_TOED_BOOTS',
  TWIC = 'TWIC'
}

export enum BrdRestrictionStatus {
  MET_REQUIREMENT = 'MET_REQUIREMENT',
  NEEDS_OVERRIDE = 'NEEDS_OVERRIDE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  OVERRIDDEN = 'OVERRIDDEN'
}

export enum BrdRestrictionType {
  CUSTOMER_NOT_ENFORCING = 'CUSTOMER_NOT_ENFORCING',
  DO_NOT_OVERRIDE = 'DO_NOT_OVERRIDE',
  MANAGEMENT_OVERRIDE = 'MANAGEMENT_OVERRIDE',
  MANUALLY_VERIFIED = 'MANUALLY_VERIFIED',
  USER_ERROR = 'USER_ERROR'
}

export type ContactInfo = {
  __typename?: 'ContactInfo';
  address?: Maybe<Address>;
  email?: Maybe<Email>;
  name?: Maybe<Name>;
  phone?: Maybe<Phone>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Email = {
  __typename?: 'Email';
  address?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EmailType>;
};

export enum EmailType {
  OTHER = 'OTHER',
  PERSONAL = 'PERSONAL',
  WORK = 'WORK'
}

export type Name = {
  __typename?: 'Name';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Phone = {
  __typename?: 'Phone';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PhoneType>;
};

export enum PhoneType {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  OTHER = 'OTHER',
  WORK = 'WORK'
}

export type IsnResultWithHeader = {
  __typename?: 'IsnResultWithHeader';
  error_message?: Maybe<Scalars['String']['output']>;
  isn_customer?: Maybe<Scalars['String']['output']>;
  isn_ownerid?: Maybe<Scalars['String']['output']>;
  isnResultType?: Maybe<Scalars['String']['output']>;
  qc_count?: Maybe<Scalars['Int']['output']>;
  qc_result?: Maybe<Array<Maybe<IsnQcResult>>>;
  quick_check_status?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  selectedResult?: Maybe<IsnQcResult>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type IsnQcResult = {
  __typename?: 'IsnQcResult';
  allowed?: Maybe<Scalars['Boolean']['output']>;
  assignedActivities?: Maybe<Scalars['String']['output']>;
  companyID?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  dataAsOf?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isnId?: Maybe<Scalars['String']['output']>;
  isnResultType?: Maybe<Scalars['String']['output']>;
  isnStatus?: Maybe<IsnStatus>;
  lastName?: Maybe<Scalars['String']['output']>;
  mostRecentTrainingExpirationDate?: Maybe<Scalars['String']['output']>;
  mostRecentTrainingQualificationExpirationName?: Maybe<Scalars['String']['output']>;
  onlineTrainingQualificationProject?: Maybe<IsnQcResultProject>;
  operatorQualificationProject?: Maybe<IsnQcResultProject>;
  quickCheckStatus?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  reasonsForNonCompliance?: Maybe<Scalars['String']['output']>;
  scCompanyCurrentPoints?: Maybe<Scalars['Float']['output']>;
  scCompanyGrade?: Maybe<Scalars['String']['output']>;
  scCompanyGradeComponentsJson?: Maybe<Scalars['AWSJSON']['output']>;
  scCompanyGradeSince?: Maybe<Scalars['AWSDate']['output']>;
  scCompanyMaxPoints?: Maybe<Scalars['Float']['output']>;
  scCompanyVariance?: Maybe<Scalars['Boolean']['output']>;
  trainingDeficiencies?: Maybe<Scalars['String']['output']>;
  trainingQualificationProject?: Maybe<IsnQcResultProject>;
  trainingStatus?: Maybe<Scalars['String']['output']>;
};

export type IsnStatus = {
  __typename?: 'IsnStatus';
  bgColor?: Maybe<Scalars['String']['output']>;
  fgColor?: Maybe<Scalars['String']['output']>;
  longLabel?: Maybe<Scalars['String']['output']>;
  shortLabel?: Maybe<Scalars['String']['output']>;
};

export type IsnQcResultProject = {
  __typename?: 'IsnQcResultProject';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  siteID?: Maybe<Scalars['String']['output']>;
};

export type CargoNode = Entity & {
  __typename?: 'CargoNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  assetID?: Maybe<Scalars['String']['output']>;
  assigned?: Maybe<Scalars['Boolean']['output']>;
  assignedLegSet?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  attentionTo?: Maybe<Scalars['String']['output']>;
  averageFlightTime?: Maybe<Scalars['Int']['output']>;
  cargoType?: Maybe<Scalars['String']['output']>;
  chargeCode?: Maybe<Scalars['String']['output']>;
  checkedIn?: Maybe<Scalars['Boolean']['output']>;
  checkedInTime?: Maybe<Scalars['AWSDateTime']['output']>;
  checkInType?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractID?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  currentCarrierID?: Maybe<FlightNode>;
  customerID?: Maybe<Customer>;
  deliveredBy?: Maybe<Scalars['String']['output']>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  firstCheckInType?: Maybe<Scalars['String']['output']>;
  groundPickup?: Maybe<Scalars['Boolean']['output']>;
  hazmat?: Maybe<Scalars['Boolean']['output']>;
  hazmatUnCode?: Maybe<Scalars['String']['output']>;
  individualLegTime?: Maybe<Scalars['AWSJSON']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  lastKnownController?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nodeState?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notesForPilot?: Maybe<Scalars['String']['output']>;
  receivedLocation?: Maybe<Location>;
  receivedLocationID?: Maybe<Location>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  scheduledOrder?: Maybe<Scalars['Int']['output']>;
  scheduledVersion?: Maybe<ScheduledVersion>;
  specialStatus?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Transporter>;
  transitType?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type FlightNode = Entity & {
  __typename?: 'FlightNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  aircraftID?: Maybe<Aircraft>;
  callSign?: Maybe<Scalars['String']['output']>;
  cgoIDList?: Maybe<Array<Maybe<CargoNode>>>;
  classType?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Contract>;
  copilot?: Maybe<Pilot>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<Customer>;
  departureID?: Maybe<Location>;
  desig?: Maybe<Scalars['String']['output']>;
  destinationID?: Maybe<Location>;
  estDepartureTime?: Maybe<Scalars['String']['output']>;
  firstLegUsableWt?: Maybe<Scalars['Int']['output']>;
  lastKnownController?: Maybe<Location>;
  legs?: Maybe<Scalars['AWSJSON']['output']>;
  legsArray?: Maybe<Array<Maybe<FlightLeg>>>;
  liveEditedState?: Maybe<LiveEditedState>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  originalFlightID?: Maybe<Scalars['ID']['output']>;
  paxIDList?: Maybe<Array<Maybe<PaxNode>>>;
  pilot?: Maybe<Pilot>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduleType?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  timeOfDeparture?: Maybe<Scalars['AWSDateTime']['output']>;
  tpID: Transporter;
  transitType?: Maybe<Scalars['String']['output']>;
  trip?: Maybe<Scalars['Int']['output']>;
};

export type Pilot = Entity & {
  __typename?: 'Pilot';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Email>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Name>;
  phoneNumber?: Maybe<Phone>;
  tpID?: Maybe<Transporter>;
};

export type FlightLeg = {
  __typename?: 'FlightLeg';
  bagCount?: Maybe<Scalars['Int']['output']>;
  bagWeight?: Maybe<Scalars['Int']['output']>;
  cgoCount?: Maybe<Scalars['Int']['output']>;
  cgoIDs?: Maybe<Array<Maybe<CargoNode>>>;
  cgoWeight?: Maybe<Scalars['Int']['output']>;
  chosen?: Maybe<Scalars['Boolean']['output']>;
  departure?: Maybe<Scalars['String']['output']>;
  departureID?: Maybe<Scalars['ID']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  destinationID?: Maybe<Scalars['ID']['output']>;
  flightTime?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  paxCount?: Maybe<Scalars['Int']['output']>;
  paxIDs?: Maybe<Array<Maybe<PaxNode>>>;
  paxWeight?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  usefulLoad?: Maybe<Scalars['Int']['output']>;
};

export type PaxNode = Entity & {
  __typename?: 'PaxNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  assigned?: Maybe<Scalars['Boolean']['output']>;
  assignedLegSet?: Maybe<Scalars['String']['output']>;
  authorization?: Maybe<Authorization>;
  averageFlightTime?: Maybe<Scalars['Int']['output']>;
  bagCount?: Maybe<Scalars['Int']['output']>;
  baggageIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  bagWeight?: Maybe<Scalars['Int']['output']>;
  bagWeightMap?: Maybe<Scalars['AWSJSON']['output']>;
  chargeCode?: Maybe<Scalars['String']['output']>;
  checkedIn?: Maybe<Scalars['Boolean']['output']>;
  checkedInTime?: Maybe<Scalars['AWSDateTime']['output']>;
  checkInCred?: Maybe<Credential>;
  checkInType?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractID?: Maybe<Contract>;
  covid_bodyTemperature?: Maybe<Scalars['Float']['output']>;
  covid_didPassBodyTempScan?: Maybe<Scalars['Boolean']['output']>;
  covid_ExposedToVirus?: Maybe<Scalars['Boolean']['output']>;
  covid_hasBeenVaccinated?: Maybe<Scalars['Boolean']['output']>;
  covid_hasFluSymptoms?: Maybe<Scalars['Boolean']['output']>;
  covid_selfReportedHighBodyTemperature?: Maybe<Scalars['Boolean']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  currentCarrierID?: Maybe<FlightNode>;
  customerID?: Maybe<Customer>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  employerID?: Maybe<Employer>;
  firstCheckInType?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  getTzName?: Maybe<Scalars['String']['output']>;
  hasCheckedSteelToesToday?: Maybe<Scalars['Boolean']['output']>;
  individualLegTime?: Maybe<Scalars['AWSJSON']['output']>;
  isnCheckError?: Maybe<IsnCheckError>;
  lastIsnResultWithHeader?: Maybe<IsnResultWithHeader>;
  lastKnownController?: Maybe<Location>;
  lastName?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  nodeState?: Maybe<Scalars['String']['output']>;
  notesForPilot?: Maybe<Scalars['String']['output']>;
  paxWeight?: Maybe<Scalars['Int']['output']>;
  personID: Person;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  scheduledOrder?: Maybe<Scalars['Int']['output']>;
  scheduledVersion?: Maybe<ScheduledVersion>;
  tpID?: Maybe<Transporter>;
  transitType?: Maybe<Scalars['String']['output']>;
};

export type Credential = Entity & {
  __typename?: 'Credential';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  expiration?: Maybe<Scalars['AWSDate']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  personID?: Maybe<Person>;
  type?: Maybe<CredentialType>;
};

export type Person = Entity & {
  __typename?: 'Person';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contactInfo?: Maybe<ContactInfo>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  credentials?: Maybe<Array<Maybe<Credential>>>;
  customerID?: Maybe<Customer>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  emergencyContactInfo?: Maybe<ContactInfo>;
  employeeNumber?: Maybe<Scalars['String']['output']>;
  employerID?: Maybe<Employer>;
  essentialOn?: Maybe<Array<Rig>>;
  extraBroadState?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastBagCount?: Maybe<Scalars['Int']['output']>;
  lastBagWeight?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastPaxWeight?: Maybe<Scalars['Int']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  python_backend_isNonPreferred?: Maybe<Scalars['Boolean']['output']>;
  vip?: Maybe<Scalars['Boolean']['output']>;
};

export enum CredentialType {
  EBS_CARD = 'EBS_CARD',
  FINGERPRINT = 'FINGERPRINT',
  HUET_CARD = 'HUET_CARD',
  ISN = 'ISN',
  PASSPORT = 'PASSPORT',
  PASSPORT_CARD = 'PASSPORT_CARD',
  SAFETY_CARD = 'SAFETY_CARD',
  TWIC = 'TWIC',
  US_DRIVERS_LICENSE = 'US_DRIVERS_LICENSE'
}

export type IsnCheckError = {
  __typename?: 'IsnCheckError';
  message?: Maybe<Scalars['String']['output']>;
};

export enum ScheduledVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export enum LiveEditedState {
  BLESSED = 'BLESSED',
  IS_EDITING = 'IS_EDITING',
  NONE = 'NONE'
}

export type Vessel = Entity & {
  __typename?: 'Vessel';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customer?: Maybe<Customer>;
  customerID?: Maybe<Scalars['ID']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Scalars['ID']['output']>;
  transporter?: Maybe<Transporter>;
};

export type ViewResult = {
  __typename?: 'ViewResult';
  offset?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<ViewResultRow>>>;
  total_rows?: Maybe<Scalars['Int']['output']>;
};

export type ViewResultRow = {
  __typename?: 'ViewResultRow';
  doc?: Maybe<Entity>;
  key?: Maybe<Scalars['AWSJSON']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DocksCargo = Entity & {
  __typename?: 'DocksCargo';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  afeNumber?: Maybe<Scalars['String']['output']>;
  assetID?: Maybe<Asset>;
  assigned?: Maybe<Scalars['Boolean']['output']>;
  assignedLegSet?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  attentionTo?: Maybe<Scalars['String']['output']>;
  belowDeck?: Maybe<Scalars['Boolean']['output']>;
  cargoType?: Maybe<Scalars['String']['output']>;
  checkedIn?: Maybe<Scalars['Boolean']['output']>;
  checkedInTime?: Maybe<Scalars['AWSDateTime']['output']>;
  checkInType?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  containerID?: Maybe<Scalars['String']['output']>;
  contractID?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  currentCarrierID?: Maybe<FlightNode>;
  customerID?: Maybe<Customer>;
  deliveredBy?: Maybe<Scalars['String']['output']>;
  departureID?: Maybe<Location>;
  description?: Maybe<Scalars['String']['output']>;
  destinationID?: Maybe<Location>;
  firstCheckInType?: Maybe<Scalars['String']['output']>;
  groundPickup?: Maybe<Scalars['Boolean']['output']>;
  hazmat?: Maybe<Scalars['Boolean']['output']>;
  hazmatUnCode?: Maybe<Scalars['String']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  lastKnownController?: Maybe<Location>;
  lifts?: Maybe<Scalars['Int']['output']>;
  liftWeight?: Maybe<Scalars['Int']['output']>;
  loe?: Maybe<Scalars['Boolean']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nodeState?: Maybe<Scalars['String']['output']>;
  offshoreCheckIn?: Maybe<Scalars['AWSDateTime']['output']>;
  offshoreCheckOut?: Maybe<Scalars['AWSDateTime']['output']>;
  ownerID?: Maybe<Employer>;
  quantity?: Maybe<Scalars['Int']['output']>;
  receivedLocation?: Maybe<Location>;
  receivedLocationID?: Maybe<Location>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  specialStatus?: Maybe<Scalars['String']['output']>;
  ticketNumber?: Maybe<Scalars['String']['output']>;
  totalWeight?: Maybe<Scalars['Int']['output']>;
  tpID?: Maybe<Transporter>;
  transitType?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type Asset = Entity & {
  __typename?: 'Asset';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<Customer>;
  deletedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  locationID?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerID?: Maybe<Employer>;
  tpID?: Maybe<Transporter>;
};

export type CountryCodes = {
  __typename?: 'CountryCodes';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<Array<Maybe<Country>>>;
};

export type Country = {
  __typename?: 'Country';
  Code: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type PobNode = Entity & {
  __typename?: 'PobNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  assignedLegSet?: Maybe<Scalars['String']['output']>;
  authorization?: Maybe<Authorization>;
  bagCount?: Maybe<Scalars['Int']['output']>;
  baggageIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  bagWeight?: Maybe<Scalars['Int']['output']>;
  bagWeightMap?: Maybe<Scalars['AWSJSON']['output']>;
  chargeCode?: Maybe<Scalars['String']['output']>;
  checkedIn?: Maybe<Scalars['Boolean']['output']>;
  checkedInTime?: Maybe<Scalars['AWSDateTime']['output']>;
  checkInCred?: Maybe<Credential>;
  checkInType?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractID?: Maybe<Contract>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  currentCarrierID?: Maybe<Carrier>;
  customerID?: Maybe<Customer>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  employerID?: Maybe<Employer>;
  firstCheckInType?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasCheckedSteelToesToday?: Maybe<Scalars['Boolean']['output']>;
  isnCheckError?: Maybe<IsnCheckError>;
  lastIsnResultWithHeader?: Maybe<IsnResultWithHeader>;
  lastKnownController?: Maybe<Location>;
  lastName?: Maybe<Scalars['String']['output']>;
  lifeBoatNumber?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  nodeState?: Maybe<Scalars['String']['output']>;
  offshoreCheckIn?: Maybe<Scalars['AWSDateTime']['output']>;
  offshoreCheckOut?: Maybe<Scalars['AWSDateTime']['output']>;
  paxWeight?: Maybe<Scalars['Int']['output']>;
  personID: Person;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  scheduledOrder?: Maybe<Scalars['Int']['output']>;
  shiftTimes?: Maybe<Array<Maybe<ShiftRecord>>>;
  tpID: Transporter;
  transitType: TransitType;
};

export type Carrier = BoatNode | FlightNode;

export type ShiftRecord = {
  __typename?: 'ShiftRecord';
  clockIn?: Maybe<Scalars['AWSDateTime']['output']>;
  clockOut?: Maybe<Scalars['AWSDateTime']['output']>;
  locationID?: Maybe<Location>;
};

export type Dispatch = Entity & {
  __typename?: 'Dispatch';
  _id: Scalars['ID']['output'];
  _r_latestRemark?: Maybe<DispatchRemark>;
  _rev?: Maybe<Scalars['ID']['output']>;
  aircraftDoc?: Maybe<Aircraft>;
  aircraftID?: Maybe<Scalars['ID']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  ata?: Maybe<Scalars['String']['output']>;
  ato?: Maybe<Scalars['String']['output']>;
  checkTime?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractDoc?: Maybe<Contract>;
  contractID?: Maybe<Scalars['ID']['output']>;
  coPilotDoc?: Maybe<Pilot>;
  coPilotID?: Maybe<Scalars['ID']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  departingDoc?: Maybe<Location>;
  departingID?: Maybe<Scalars['ID']['output']>;
  destinationDoc?: Maybe<Location>;
  destinationID?: Maybe<Scalars['ID']['output']>;
  enRouteCheck?: Maybe<Scalars['Int']['output']>;
  eta?: Maybe<Scalars['String']['output']>;
  fuel?: Maybe<Scalars['Int']['output']>;
  fuelTaken?: Maybe<Scalars['AWSJSON']['output']>;
  getTzAbbr: Scalars['String']['output'];
  getTzName: Scalars['String']['output'];
  lastKnownController?: Maybe<Scalars['ID']['output']>;
  lastKnownControllerDoc?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pilotDoc?: Maybe<Pilot>;
  pilotID?: Maybe<Scalars['ID']['output']>;
  pob?: Maybe<Scalars['Int']['output']>;
  remark?: Maybe<Array<DispatchRemark>>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  specialsAckd?: Maybe<Scalars['AWSJSON']['output']>;
  status?: Maybe<DispatchStatus>;
  tpDoc?: Maybe<Transporter>;
  tpID?: Maybe<Scalars['ID']['output']>;
};

export type DispatchRemark = {
  __typename?: 'DispatchRemark';
  remark?: Maybe<Scalars['String']['output']>;
  remarkTime?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum DispatchStatus {
  AIR = 'AIR',
  BASE = 'BASE',
  CHECK = 'CHECK'
}

export type HistoryFlightNode = Entity & {
  __typename?: 'HistoryFlightNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  aircraftID?: Maybe<HistoryAircraft>;
  callSign?: Maybe<Scalars['String']['output']>;
  cgoIDList?: Maybe<Array<Maybe<HistoryCargoNode>>>;
  classType?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<HistoryContract>;
  copilot?: Maybe<HistoryPilot>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<HistoryCustomer>;
  departureID?: Maybe<HistoryLocation>;
  desig?: Maybe<Scalars['String']['output']>;
  destinationID?: Maybe<HistoryLocation>;
  firstLegUsableWt?: Maybe<Scalars['Int']['output']>;
  lastKnownController?: Maybe<HistoryLocation>;
  legs?: Maybe<Scalars['AWSJSON']['output']>;
  legsArray?: Maybe<Array<Maybe<HistoryFlightLeg>>>;
  liveEditedState?: Maybe<LiveEditedState>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  originalFlightID?: Maybe<Scalars['ID']['output']>;
  paxIDList?: Maybe<Array<Maybe<HistoryPaxNode>>>;
  pilot?: Maybe<HistoryPilot>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduleType?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  timeOfDeparture?: Maybe<Scalars['AWSDateTime']['output']>;
  tpID: HistoryTransporter;
  transitType?: Maybe<Scalars['String']['output']>;
  trip?: Maybe<Scalars['Int']['output']>;
};

export type HistoryAircraft = Entity & {
  __typename?: 'HistoryAircraft';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  maxLoad?: Maybe<Scalars['Int']['output']>;
  maxSeats?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  tailNum?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<HistoryTransporter>;
};

export type HistoryTransporter = Entity & OrganizationInterface & {
  __typename?: 'HistoryTransporter';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  tpID?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryCargoNode = Entity & {
  __typename?: 'HistoryCargoNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  assetID?: Maybe<Scalars['String']['output']>;
  assigned?: Maybe<Scalars['Boolean']['output']>;
  assignedLegSet?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  attentionTo?: Maybe<Scalars['String']['output']>;
  averageFlightTime?: Maybe<Scalars['Int']['output']>;
  cargoType?: Maybe<Scalars['String']['output']>;
  chargeCode?: Maybe<Scalars['String']['output']>;
  checkedIn?: Maybe<Scalars['Boolean']['output']>;
  checkedInTime?: Maybe<Scalars['AWSDateTime']['output']>;
  checkInType?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractID?: Maybe<HistoryContract>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  currentCarrierID?: Maybe<HistoryFlightNode>;
  customerDoc?: Maybe<HistoryCustomer>;
  customerID?: Maybe<Scalars['ID']['output']>;
  deliveredBy?: Maybe<Scalars['String']['output']>;
  departureDoc?: Maybe<HistoryLocation>;
  departureID?: Maybe<Scalars['ID']['output']>;
  destinationDoc?: Maybe<HistoryLocation>;
  destinationID?: Maybe<Scalars['ID']['output']>;
  firstCheckInType?: Maybe<Scalars['String']['output']>;
  groundPickup?: Maybe<Scalars['Boolean']['output']>;
  hazmat?: Maybe<Scalars['Boolean']['output']>;
  hazmatUnCode?: Maybe<Scalars['String']['output']>;
  individualLegTime?: Maybe<Scalars['AWSJSON']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  lastKnownController?: Maybe<HistoryLocation>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nodeState?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  receivedLocation?: Maybe<HistoryLocation>;
  receivedLocationID?: Maybe<HistoryLocation>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  scheduledOrder?: Maybe<Scalars['Int']['output']>;
  specialStatus?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<HistoryTransporter>;
  transitType?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type HistoryContract = Entity & {
  __typename?: 'HistoryContract';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID: HistoryOrganization;
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  locationID?: Maybe<HistoryLocation>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['AWSDate']['output']>;
  tpID: HistoryTransporter;
};

export type HistoryOrganization = OrganizationInterface & {
  __typename?: 'HistoryOrganization';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryLocation = Entity & {
  __typename?: 'HistoryLocation';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  block?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerOrg?: Maybe<Scalars['String']['output']>;
  twicRequired?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<LocationType>;
};

export type HistoryCustomer = Entity & OrganizationInterface & {
  __typename?: 'HistoryCustomer';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  ebsAirCanisterRequired?: Maybe<Scalars['Boolean']['output']>;
  employerEvaluationPercentage?: Maybe<Array<Maybe<HistoryEmployerEvaluationPercentage>>>;
  extraBroadCheckRequired?: Maybe<Scalars['Boolean']['output']>;
  illnessCheckRequired?: Maybe<Scalars['Boolean']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quarantinedCheckRequired?: Maybe<Scalars['Boolean']['output']>;
  quarantinedCountries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  quarantineTravelPeriod?: Maybe<Scalars['Int']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  steelToeIDDestinations?: Maybe<Array<Maybe<HistoryLocation>>>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryEmployerEvaluationPercentage = {
  __typename?: 'HistoryEmployerEvaluationPercentage';
  employerID: HistoryEmployer;
  percentage?: Maybe<Scalars['Int']['output']>;
};

export type HistoryEmployer = Entity & OrganizationInterface & {
  __typename?: 'HistoryEmployer';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  isnName?: Maybe<Scalars['String']['output']>;
  licenseName?: Maybe<Scalars['String']['output']>;
  manifestPrintout?: Maybe<Array<Maybe<ManifestPrintoutType>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requireOverrideFields1?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<OrganizationType>;
  useV1Scheduler?: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryPilot = Entity & {
  __typename?: 'HistoryPilot';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  email?: Maybe<Email>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Name>;
  phoneNumber?: Maybe<Phone>;
  tpID?: Maybe<HistoryTransporter>;
};

export type HistoryFlightLeg = {
  __typename?: 'HistoryFlightLeg';
  bagCount?: Maybe<Scalars['Int']['output']>;
  bagWeight?: Maybe<Scalars['Int']['output']>;
  cgoCount?: Maybe<Scalars['Int']['output']>;
  cgoIDs?: Maybe<Array<Maybe<HistoryCargoNode>>>;
  cgoWeight?: Maybe<Scalars['Int']['output']>;
  chosen?: Maybe<Scalars['Boolean']['output']>;
  departure?: Maybe<Scalars['String']['output']>;
  departureID?: Maybe<Scalars['ID']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  destinationID?: Maybe<Scalars['ID']['output']>;
  flightTime?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  paxCount?: Maybe<Scalars['Int']['output']>;
  paxIDs?: Maybe<Array<Maybe<HistoryPaxNode>>>;
  paxWeight?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  usefulLoad?: Maybe<Scalars['Int']['output']>;
};

export type HistoryPaxNode = Entity & {
  __typename?: 'HistoryPaxNode';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  assigned?: Maybe<Scalars['Boolean']['output']>;
  assignedLegSet?: Maybe<Scalars['String']['output']>;
  authorization?: Maybe<Authorization>;
  averageFlightTime?: Maybe<Scalars['Int']['output']>;
  bagCount?: Maybe<Scalars['Int']['output']>;
  baggageIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  bagWeight?: Maybe<Scalars['Int']['output']>;
  bagWeightMap?: Maybe<Scalars['AWSJSON']['output']>;
  chargeCode?: Maybe<Scalars['String']['output']>;
  checkedIn?: Maybe<Scalars['Boolean']['output']>;
  checkedInTime?: Maybe<Scalars['AWSDateTime']['output']>;
  checkInCred?: Maybe<HistoryCredential>;
  checkInType?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractID?: Maybe<HistoryContract>;
  covid_bodyTemperature?: Maybe<Scalars['Float']['output']>;
  covid_didPassBodyTempScan?: Maybe<Scalars['Boolean']['output']>;
  covid_ExposedToVirus?: Maybe<Scalars['Boolean']['output']>;
  covid_hasBeenVaccinated?: Maybe<Scalars['Boolean']['output']>;
  covid_hasFluSymptoms?: Maybe<Scalars['Boolean']['output']>;
  covid_selfReportedHighBodyTemperature?: Maybe<Scalars['Boolean']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  currentCarrierID?: Maybe<HistoryFlightNode>;
  customerDoc?: Maybe<HistoryCustomer>;
  customerID?: Maybe<Scalars['ID']['output']>;
  departureDoc?: Maybe<HistoryLocation>;
  departureID?: Maybe<Scalars['ID']['output']>;
  destinationDoc?: Maybe<HistoryLocation>;
  destinationID?: Maybe<Scalars['ID']['output']>;
  employerDoc?: Maybe<HistoryEmployer>;
  employerID?: Maybe<Scalars['ID']['output']>;
  firstCheckInType?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasCheckedSteelToesToday?: Maybe<Scalars['Boolean']['output']>;
  individualLegTime?: Maybe<Scalars['AWSJSON']['output']>;
  isnCheckError?: Maybe<IsnCheckError>;
  lastIsnResultWithHeader?: Maybe<IsnResultWithHeader>;
  lastKnownController?: Maybe<HistoryLocation>;
  lastName?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  nodeState?: Maybe<Scalars['String']['output']>;
  paxWeight?: Maybe<Scalars['Int']['output']>;
  personDoc?: Maybe<HistoryPerson>;
  personID?: Maybe<Scalars['ID']['output']>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  scheduledOrder?: Maybe<Scalars['Int']['output']>;
  tpID?: Maybe<HistoryTransporter>;
  transitType?: Maybe<Scalars['String']['output']>;
};

export type HistoryCredential = Entity & {
  __typename?: 'HistoryCredential';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  expiration?: Maybe<Scalars['AWSDate']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  personID?: Maybe<HistoryPerson>;
  type?: Maybe<CredentialType>;
};

export type HistoryPerson = Entity & {
  __typename?: 'HistoryPerson';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contactInfo?: Maybe<ContactInfo>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  credentials?: Maybe<Array<Maybe<HistoryCredential>>>;
  customerID?: Maybe<HistoryCustomer>;
  dob?: Maybe<Scalars['AWSDate']['output']>;
  emergencyContactInfo?: Maybe<ContactInfo>;
  employeeNumber?: Maybe<Scalars['String']['output']>;
  employerID?: Maybe<HistoryEmployer>;
  extraBroadState?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
  vip?: Maybe<Scalars['Boolean']['output']>;
};

export type NonPreferred = Entity & {
  __typename?: 'NonPreferred';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  organizationIds?: Maybe<Array<Maybe<NonPreferredOrganization>>>;
  personIds?: Maybe<Array<Maybe<NonPreferredPerson>>>;
};

export type NonPreferredOrganization = {
  __typename?: 'NonPreferredOrganization';
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  id?: Maybe<OrganizationTypes>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['AWSDate']['output']>;
};

export type OrganizationTypes = Customer | Employer | Transporter;

export type NonPreferredPerson = {
  __typename?: 'NonPreferredPerson';
  endDate?: Maybe<Scalars['AWSDate']['output']>;
  id?: Maybe<Person>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['AWSDate']['output']>;
};

export type ScheduledPaxCgoResult = {
  __typename?: 'ScheduledPaxCgoResult';
  cgonodes?: Maybe<Array<Maybe<CargoNode>>>;
  combined?: Maybe<Array<Maybe<PaxNodeCgoNodeUnion>>>;
  paxnodes?: Maybe<Array<Maybe<PaxNode>>>;
};

export type PaxNodeCgoNodeUnion = CargoNode | PaxNode;

export type PersonCov19VaxRecord = {
  __typename?: 'PersonCov19VaxRecord';
  comment?: Maybe<Scalars['String']['output']>;
  customerID?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  employerID?: Maybe<Scalars['String']['output']>;
  employerName?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  personID: Scalars['String']['output'];
  personName?: Maybe<Scalars['String']['output']>;
};

export type PersonSet = {
  __typename?: 'PersonSet';
  _id?: Maybe<Scalars['ID']['output']>;
  _rev?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  customerID?: Maybe<Customer>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  inbound?: Maybe<Array<Maybe<Person>>>;
  lastScheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  outbound?: Maybe<Array<Maybe<Person>>>;
  owner?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Transporter>;
};

export type ScheduledPaxCgoGroupV2 = {
  __typename?: 'ScheduledPaxCgoGroupV2';
  cgoInbound?: Maybe<Scalars['Int']['output']>;
  cgoInboundOnFlight?: Maybe<Scalars['Int']['output']>;
  cgoOutbound?: Maybe<Scalars['Int']['output']>;
  cgoOutboundOnFlight?: Maybe<Scalars['Int']['output']>;
  cgoTransfer?: Maybe<Scalars['Int']['output']>;
  cgoTransferOnFlight?: Maybe<Scalars['Int']['output']>;
  customerID?: Maybe<Scalars['ID']['output']>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  lastKnownController?: Maybe<Location>;
  legCount?: Maybe<Scalars['Int']['output']>;
  legs?: Maybe<Array<ScheduledPaxCgoGroupV2Leg>>;
  paxInbound?: Maybe<Scalars['Int']['output']>;
  paxInboundOnFlight?: Maybe<Scalars['Int']['output']>;
  paxOutbound?: Maybe<Scalars['Int']['output']>;
  paxOutboundOnFlight?: Maybe<Scalars['Int']['output']>;
  paxTransfer?: Maybe<Scalars['Int']['output']>;
  paxTransferOnFlight?: Maybe<Scalars['Int']['output']>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Scalars['ID']['output']>;
};

export type ScheduledPaxCgoGroupV2Leg = {
  __typename?: 'ScheduledPaxCgoGroupV2Leg';
  cgonodeDocs: Array<CargoNode>;
  cgonodeIDs: Array<Scalars['ID']['output']>;
  departureDoc?: Maybe<Location>;
  departureID: Scalars['ID']['output'];
  destinationDoc?: Maybe<Location>;
  destinationID: Scalars['ID']['output'];
  paxnodeDocs: Array<PaxNode>;
  paxnodeIDs: Array<Scalars['ID']['output']>;
  scheduledOrder: Scalars['Int']['output'];
  totalBagCount: Scalars['Float']['output'];
  totalBagWeight: Scalars['Float']['output'];
  totalCgoWeight: Scalars['Float']['output'];
  totalPaxWeight: Scalars['Float']['output'];
  totalWeight: Scalars['Float']['output'];
};

export type ScheduledPaxNodeGroup = {
  __typename?: 'ScheduledPaxNodeGroup';
  cgoInbound?: Maybe<Scalars['Int']['output']>;
  cgoInboundOnFlight?: Maybe<Scalars['Int']['output']>;
  cgoOutbound?: Maybe<Scalars['Int']['output']>;
  cgoOutboundOnFlight?: Maybe<Scalars['Int']['output']>;
  cgoTransfer?: Maybe<Scalars['Int']['output']>;
  cgoTransferOnFlight?: Maybe<Scalars['Int']['output']>;
  customerID?: Maybe<Scalars['ID']['output']>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  lastKnownController?: Maybe<Location>;
  paxInbound?: Maybe<Scalars['Int']['output']>;
  paxInboundOnFlight?: Maybe<Scalars['Int']['output']>;
  paxOutbound?: Maybe<Scalars['Int']['output']>;
  paxOutboundOnFlight?: Maybe<Scalars['Int']['output']>;
  paxTransfer?: Maybe<Scalars['Int']['output']>;
  paxTransferOnFlight?: Maybe<Scalars['Int']['output']>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Scalars['ID']['output']>;
};

export type ScheduleHistoryDetail = {
  __typename?: 'ScheduleHistoryDetail';
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  lastKnownController?: Maybe<Scalars['ID']['output']>;
  paxInboundCount?: Maybe<Scalars['Int']['output']>;
  paxList: Array<PaxNode>;
  paxOutboundCount?: Maybe<Scalars['Int']['output']>;
  paxTransferCount?: Maybe<Scalars['Int']['output']>;
};

export type WhiteList = Entity & {
  __typename?: 'WhiteList';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  locationToEmployer?: Maybe<Array<Maybe<LocationToEmployerMap>>>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  noncomplyPassList_CompanyGrade?: Maybe<Array<WhiteListPersonToNonComplyBypassMap>>;
  noncomplyPassList_DrugAndAlcohol?: Maybe<Array<WhiteListPersonToNonComplyBypassMap>>;
  noncomplyPassList_Training?: Maybe<Array<WhiteListPersonToNonComplyBypassMap>>;
  organizationIds?: Maybe<Array<Maybe<OrganizationTypes>>>;
  personIds?: Maybe<Array<Maybe<Person>>>;
};

export type LocationToEmployerMap = {
  __typename?: 'LocationToEmployerMap';
  employerIDs?: Maybe<Array<Maybe<Employer>>>;
  locationID: Location;
};

export type WhiteListPersonToNonComplyBypassMap = {
  __typename?: 'WhiteListPersonToNonComplyBypassMap';
  nonComplyBypass?: Maybe<NonComplyBypass>;
  personID: Scalars['ID']['output'];
};

export type NonComplyBypass = {
  __typename?: 'NonComplyBypass';
  cognitoUser?: Maybe<CognitoUserInfo>;
  customerID?: Maybe<Scalars['String']['output']>;
  expiresOn?: Maybe<Scalars['AWSDate']['output']>;
  nonComplyCategory?: Maybe<Scalars['String']['output']>;
  personID?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type CognitoUserInfo = {
  __typename?: 'CognitoUserInfo';
  congitoUsername?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
};

export type ListAvailableNotifiersResponse = {
  __typename?: 'ListAvailableNotifiersResponse';
  availableNotifierCount: Scalars['Int']['output'];
  availableNotifiers: Array<AvailableNotifier>;
};

export type AvailableNotifier = {
  __typename?: 'AvailableNotifier';
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
  notificationType: Scalars['String']['output'];
  triggeringDataType: Scalars['String']['output'];
};

export type ScheduleHistoryListItem = {
  __typename?: 'ScheduleHistoryListItem';
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  lastKnownController?: Maybe<Scalars['ID']['output']>;
  paxCount?: Maybe<Scalars['Int']['output']>;
  paxInboundCount?: Maybe<Scalars['Int']['output']>;
  paxOutboundCount?: Maybe<Scalars['Int']['output']>;
  paxTransferCount?: Maybe<Scalars['Int']['output']>;
};

export type LocationsFilterInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  hasDataflytHardware?: InputMaybe<Scalars['Boolean']['input']>;
  includeOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LocationType>;
};

export type FilterByKeyValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type OrganizationsFilterInput = {
  classTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PassengersFilterInput = {
  checkInTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  departures?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  destinations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  employers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  flights?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ignoreCheckInTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nodeStates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  onlyAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  onlyShowLive?: InputMaybe<Scalars['Boolean']['input']>;
  onlyUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  passengers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  transitTypes?: InputMaybe<Array<InputMaybe<TransitType>>>;
  transporter?: InputMaybe<Scalars['ID']['input']>;
  transporters?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PersonFilterInput = {
  customer?: InputMaybe<Array<Scalars['ID']['input']>>;
  employer?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PilotsFilterInput = {
  name?: InputMaybe<NameInput>;
  tpID?: InputMaybe<Scalars['ID']['input']>;
};

export type NameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Report = Entity & {
  __typename?: 'Report';
  _id: Scalars['ID']['output'];
  _rev?: Maybe<Scalars['ID']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  creationMetaData?: Maybe<CreationMetaData>;
  criteria?: Maybe<Array<Maybe<ReportCriteria>>>;
  endTs?: Maybe<Scalars['AWSDateTime']['output']>;
  error?: Maybe<ReportError>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  result?: Maybe<ReportResult>;
  startTs?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<ReportStatus>;
  tpID?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ReportType>;
};

export type CreationMetaData = {
  __typename?: 'CreationMetaData';
  email?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ReportCriteria = {
  __typename?: 'ReportCriteria';
  display_value?: Maybe<Scalars['AWSJSON']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['AWSJSON']['output']>;
};

export type ReportError = {
  __typename?: 'ReportError';
  args?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  detailMessage?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ReportResult = {
  __typename?: 'ReportResult';
  docs?: Maybe<Scalars['AWSJSON']['output']>;
  refs?: Maybe<Scalars['AWSJSON']['output']>;
  summary?: Maybe<Scalars['AWSJSON']['output']>;
  total_docs?: Maybe<Scalars['Int']['output']>;
  total_pages?: Maybe<Scalars['Int']['output']>;
};

export enum ReportStatus {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  INITIALIZING = 'INITIALIZING'
}

export enum ReportType {
  CGO = 'CGO',
  COVID = 'COVID',
  DISPATCH_BILLING = 'DISPATCH_BILLING',
  DISPATCH_CHANGES_BY_CONSOLE = 'DISPATCH_CHANGES_BY_CONSOLE',
  DISPATCH_FUEL_TAKEN_S3 = 'DISPATCH_FUEL_TAKEN_S3',
  DISPATCH_HISTORY = 'DISPATCH_HISTORY',
  ISN = 'ISN',
  MANIFEST = 'MANIFEST',
  PAX = 'PAX',
  PAX_COVID19_VAX = 'PAX_COVID19_VAX',
  PAX_ISN_EVENTS = 'PAX_ISN_EVENTS',
  PERSON_COVID19_VAX_DETAILS = 'PERSON_COVID19_VAX_DETAILS',
  PERSON_HOTFUEL = 'PERSON_HOTFUEL'
}

export type ReportsFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  endTs?: InputMaybe<Scalars['AWSDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startTs?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<ReportType>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ReportCriteriaInput = {
  display_value?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  resolve?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type VesselsFilterInput = {
  customerID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tpID?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addPassengersToFlight?: Maybe<FlightNode>;
  addPaxAndCgoToFlight?: Maybe<FlightNode>;
  AdminCognitoApproveUser?: Maybe<Scalars['AWSJSON']['output']>;
  AdminCognitoCreateUser: Scalars['AWSJSON']['output'];
  AdminCognitoDeleteUser?: Maybe<Scalars['AWSJSON']['output']>;
  AdminCognitoDenyUser?: Maybe<Scalars['AWSJSON']['output']>;
  AdminCognitoDisableUser?: Maybe<Scalars['Boolean']['output']>;
  AdminCognitoRevokeApproval?: Maybe<Scalars['AWSJSON']['output']>;
  AdminCognitoUpdateUserAttributes?: Maybe<Scalars['AWSJSON']['output']>;
  AdminCognitoUpdateUserGroups?: Maybe<Scalars['AWSJSON']['output']>;
  AdminUpdateUserSettings: WebUserSettings;
  ApproveDispatchFlightHistory?: Maybe<ApproveDispatchFlightHistoryResponse>;
  boatCrewOverride?: Maybe<BoatCrew>;
  bulkDeletePersonCovid19VaxDate?: Maybe<Array<PersonCov19VaxRecord>>;
  /**
   * addPersonCovid19VaxDate(
   *   personID: String!
   *   label: String!
   *   personName: String!
   *   comment: String
   *   manufacturer: String!
   *   date: String!
   * ): PersonCov19VaxRecord!
   *   @aws_auth(cognito_groups : ["flytsuite.covid19vaxrecords.edit"])
   * updatePersonCovid19VaxDate(personID: String!, label: String!, date: String!, comment: String, manufacturer: String!): PersonCov19VaxRecord!
   *   @aws_auth(cognito_groups : ["flytsuite.covid19vaxrecords.edit"])
   * deletePersonCovid19VaxDate(personID: String!, date: String!): PersonCov19VaxRecord
   *   @aws_auth(cognito_groups : ["flytsuite.covid19vaxrecords.delete"])
   */
  bulkUpdatePersonCovid19VaxDate?: Maybe<Array<PersonCov19VaxRecord>>;
  ChangePaxCheckInType?: Maybe<PaxNode>;
  CheckInPassengerDocks?: Maybe<PaxNode>;
  CheckInPassengerFlight?: Maybe<PaxNode>;
  CreateRigFromLoc?: Maybe<Rig>;
  DeleteDispatchFlightHistory?: Maybe<DeleteDispatchFlightHistoryResponse>;
  deleteEmployers?: Maybe<Array<Maybe<Employer>>>;
  deleteEntity?: Maybe<Scalars['AWSDateTime']['output']>;
  deleteFlight?: Maybe<FlightNode>;
  DeleteFuelTaken?: Maybe<FuelTaken>;
  editFlightHistory?: Maybe<HistoryFlightNode>;
  export_data?: Maybe<Scalars['String']['output']>;
  export_report?: Maybe<Scalars['String']['output']>;
  finalizeBoat?: Maybe<BoatNode>;
  overridePassengerRestriction?: Maybe<PaxNode>;
  paxOverride?: Maybe<PaxNode>;
  removePassengersFromBoat?: Maybe<BoatNode>;
  revertFlightHistory?: Maybe<HistoryFlightNode>;
  run_report?: Maybe<Scalars['ID']['output']>;
  run_report_java?: Maybe<Scalars['ID']['output']>;
  save_flight_times?: Maybe<HistoryFlightNode>;
  schedulePassengers?: Maybe<Array<Maybe<PaxNode>>>;
  SchedulePaxCgo_V2?: Maybe<ScheduledPaxCgoResult>;
  schedulePaxInbCgo?: Maybe<ScheduledPaxCgoResult>;
  SendUserFeedback: Scalars['AWSJSON']['output'];
  setAircraft?: Maybe<Aircraft>;
  setAircraftAustin?: Maybe<Aircraft>;
  setBoatCrewIsnCheck?: Maybe<BoatCrew>;
  setBoatCrewISNSelection?: Maybe<BoatCrew>;
  setCargoNode?: Maybe<CargoNode>;
  setContract?: Maybe<Contract>;
  setCredential?: Maybe<Credential>;
  setCustomer?: Maybe<Customer>;
  setEmployer?: Maybe<Employer>;
  SetEssenPerToRig?: Maybe<Rig>;
  setFlight?: Maybe<FlightNode>;
  setIsnSelection?: Maybe<PaxNode>;
  setLocation?: Maybe<Location>;
  setNonPreferred?: Maybe<NonPreferred>;
  setPassengerIsnCheck?: Maybe<PaxNode>;
  setPassengerNoFlyCheck?: Maybe<PaxNode>;
  setPaxNode?: Maybe<PaxNode>;
  setPerson?: Maybe<Person>;
  SetPersonOnWhiteList?: Maybe<WhiteList>;
  setPersonSet?: Maybe<PersonSet>;
  setPilot?: Maybe<Pilot>;
  SetRemoteUserAppSettings: WebUserAppSettings;
  setScheduledToExpected?: Maybe<FlightNode>;
  SetUserOrgNotificationEmailList?: Maybe<OrganizationTypes>;
  setVessel?: Maybe<Vessel>;
  setWhiteList?: Maybe<WhiteList>;
  UploadFuelTaken?: Maybe<FuelTaken>;
};


export type MutationAddPassengersToFlightArgs = {
  flight: FlightNodeInput;
  paxIDs: Array<InputMaybe<Scalars['ID']['input']>>;
  tpID: Scalars['ID']['input'];
};


export type MutationAddPaxAndCgoToFlightArgs = {
  cgoIDs: Array<InputMaybe<Scalars['ID']['input']>>;
  flight: FlightNodeInput;
  paxIDs: Array<InputMaybe<Scalars['ID']['input']>>;
  tpID: Scalars['ID']['input'];
};


export type MutationAdminCognitoApproveUserArgs = {
  Username: Scalars['String']['input'];
};


export type MutationAdminCognitoCreateUserArgs = {
  attributes: Scalars['AWSJSON']['input'];
  desiredDeliveryMediums?: InputMaybe<Array<Scalars['String']['input']>>;
  tempPassword?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};


export type MutationAdminCognitoDeleteUserArgs = {
  username: Scalars['String']['input'];
};


export type MutationAdminCognitoDenyUserArgs = {
  Username: Scalars['String']['input'];
};


export type MutationAdminCognitoDisableUserArgs = {
  enableUser?: InputMaybe<Scalars['Boolean']['input']>;
  username: Scalars['String']['input'];
};


export type MutationAdminCognitoRevokeApprovalArgs = {
  Username: Scalars['String']['input'];
};


export type MutationAdminCognitoUpdateUserAttributesArgs = {
  attributes?: InputMaybe<Scalars['AWSJSON']['input']>;
  deletedAttributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  username: Scalars['String']['input'];
};


export type MutationAdminCognitoUpdateUserGroupsArgs = {
  addGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  removeGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  username: Scalars['String']['input'];
};


export type MutationAdminUpdateUserSettingsArgs = {
  cognitoUsername: Scalars['String']['input'];
  contracts?: InputMaybe<Array<Scalars['ID']['input']>>;
  createNew?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
};


export type MutationApproveDispatchFlightHistoryArgs = {
  aircraftID: Scalars['ID']['input'];
  changedHistory?: InputMaybe<Array<ApproveDispatchFlightHistoryInput>>;
  deleteHistoryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  endTime: Scalars['AWSDateTime']['input'];
  startTime: Scalars['AWSDateTime']['input'];
  tpID: Scalars['ID']['input'];
  tzAbbr: Scalars['String']['input'];
};


export type MutationBoatCrewOverrideArgs = {
  _id: Scalars['ID']['input'];
  _rev: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrdRestrictionType>;
};


export type MutationBulkDeletePersonCovid19VaxDateArgs = {
  input: Array<BulkDeletePersonCov19VaxRecordInput>;
};


export type MutationBulkUpdatePersonCovid19VaxDateArgs = {
  input: Array<BulkUpdatePersonCov19VaxRecordInput>;
};


export type MutationChangePaxCheckInTypeArgs = {
  newCheckInType: Scalars['String']['input'];
  paxID: Scalars['ID']['input'];
};


export type MutationCheckInPassengerDocksArgs = {
  expected?: InputMaybe<Scalars['Boolean']['input']>;
  payload: CheckInPaxDocksPayload;
};


export type MutationCheckInPassengerFlightArgs = {
  payload: CheckInPaxFlightPayload;
};


export type MutationCreateRigFromLocArgs = {
  locationID: Scalars['ID']['input'];
};


export type MutationDeleteDispatchFlightHistoryArgs = {
  deleteHistoryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  tzAbbr: Scalars['String']['input'];
};


export type MutationDeleteEmployersArgs = {
  id: Array<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type MutationDeleteEntityArgs = {
  _id: Scalars['ID']['input'];
  _rev?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type MutationDeleteFlightArgs = {
  _id: Scalars['ID']['input'];
  _rev?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
};


export type MutationDeleteFuelTakenArgs = {
  fuelTaken: FuelTakenInput;
  tpID: Scalars['String']['input'];
};


export type MutationEditFlightHistoryArgs = {
  flight: FlightNodeInput;
  flightID: Scalars['ID']['input'];
  orgID: Scalars['ID']['input'];
  times: Scalars['AWSJSON']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationExport_DataArgs = {
  column_sets: Array<ExportReportColumnSetInput>;
  content_type: Scalars['String']['input'];
  criteria: Array<InputMaybe<ReportCriteriaInput>>;
  data: Scalars['AWSJSON']['input'];
  export_as_zip?: InputMaybe<Scalars['Boolean']['input']>;
  export_name: Scalars['String']['input'];
  file_type: Scalars['String']['input'];
  report_type: Scalars['String']['input'];
  titleRowText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExport_ReportArgs = {
  column_sets: Array<ExportReportColumnSetInput>;
  content_type: Scalars['String']['input'];
  export_as_zip?: InputMaybe<Scalars['Boolean']['input']>;
  file_type: Scalars['String']['input'];
  report_id: Scalars['ID']['input'];
  titleRowText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFinalizeBoatArgs = {
  _id: Scalars['ID']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationOverridePassengerRestrictionArgs = {
  _id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  overrideType: BrdRestrictionType;
  restrictionType: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationPaxOverrideArgs = {
  _id: Scalars['ID']['input'];
  _rev: Scalars['ID']['input'];
  approverFirstName?: InputMaybe<Scalars['String']['input']>;
  approverLastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  orgID: Scalars['ID']['input'];
  overriderFirstName?: InputMaybe<Scalars['String']['input']>;
  overriderLastName?: InputMaybe<Scalars['String']['input']>;
  overriderReason?: InputMaybe<Scalars['String']['input']>;
  overriderTitle?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  redWhiteOverride?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrdRestrictionType>;
};


export type MutationRemovePassengersFromBoatArgs = {
  boatID: Scalars['ID']['input'];
  paxIDs: Array<InputMaybe<Scalars['ID']['input']>>;
  tpID: Scalars['ID']['input'];
};


export type MutationRevertFlightHistoryArgs = {
  flightID: Scalars['ID']['input'];
};


export type MutationRun_ReportArgs = {
  contractIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  criteria: Array<ReportCriteriaInput>;
  name: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
  type: ReportType;
};


export type MutationRun_Report_JavaArgs = {
  criteria: Array<ReportCriteriaInput>;
  name: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
  type: ReportType;
};


export type MutationSave_Flight_TimesArgs = {
  flightID: Scalars['ID']['input'];
  payload: Scalars['AWSJSON']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSchedulePassengersArgs = {
  customerID: Scalars['ID']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  inboundCargo?: InputMaybe<Array<InputMaybe<ScheduledInboundCargoInput>>>;
  lastKnownController?: InputMaybe<Scalars['String']['input']>;
  persons?: InputMaybe<Array<InputMaybe<PersonScheduleInput>>>;
  scheduledFlightDate: Scalars['AWSDate']['input'];
  scheduledGroup: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSchedulePaxCgo_V2Args = {
  customerID: Scalars['ID']['input'];
  deletedEntities?: InputMaybe<Array<DeletedScheduledEntityInput>>;
  lastKnownController: Scalars['ID']['input'];
  modifiedCgo: Array<ScheduledCargoInput_V2>;
  modifiedPax: Array<ScheduledPassengerInput_V2>;
  scheduledFlightDate: Scalars['AWSDate']['input'];
  scheduledGroup: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSchedulePaxInbCgoArgs = {
  checkWhitelisted?: InputMaybe<Scalars['Boolean']['input']>;
  customerID: Scalars['ID']['input'];
  deletedEntities?: InputMaybe<Array<DeletedScheduledEntityInput>>;
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  inboundCargo: Array<ScheduledInboundCargoInput>;
  lastKnownController: Scalars['ID']['input'];
  orgID: Scalars['ID']['input'];
  passengers: Array<ScheduledPassengerInput>;
  scheduledFlightDate: Scalars['AWSDate']['input'];
  scheduledGroup: Scalars['String']['input'];
};


export type MutationSendUserFeedbackArgs = {
  actual?: InputMaybe<Scalars['String']['input']>;
  appName: Scalars['String']['input'];
  appUrlPath?: InputMaybe<Scalars['String']['input']>;
  attachmentKeyList?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  expected?: InputMaybe<Scalars['String']['input']>;
  jobPosition?: InputMaybe<Scalars['String']['input']>;
  mood?: InputMaybe<Scalars['String']['input']>;
  responseEmail?: InputMaybe<Scalars['AWSEmail']['input']>;
  responsePhone?: InputMaybe<Scalars['AWSPhone']['input']>;
  responseType: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationSetAircraftArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  maxLoad: Scalars['Int']['input'];
  maxSeats: Scalars['Int']['input'];
  model: Scalars['String']['input'];
  tailNum: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSetAircraftAustinArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  maxLoad: Scalars['Int']['input'];
  maxSeats: Scalars['Int']['input'];
  model: Scalars['String']['input'];
  tailNum: Scalars['String']['input'];
  tpID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSetBoatCrewIsnCheckArgs = {
  _id: Scalars['ID']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSetBoatCrewIsnSelectionArgs = {
  _id: Scalars['ID']['input'];
  _rev: Scalars['ID']['input'];
  payload: Scalars['AWSJSON']['input'];
};


export type MutationSetCargoNodeArgs = {
  payload?: InputMaybe<CargoNodeInput>;
};


export type MutationSetContractArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  active: Scalars['Boolean']['input'];
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  locationID?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSetCredentialArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  expiration?: InputMaybe<Scalars['AWSDate']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  paxID?: InputMaybe<Scalars['ID']['input']>;
  personID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
  type?: InputMaybe<CredentialType>;
};


export type MutationSetCustomerArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  askHaveYouBeenVaccinatedQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  ebsAirCanisterRequired?: InputMaybe<Scalars['Boolean']['input']>;
  extraBroadCheckRequired?: InputMaybe<Scalars['Boolean']['input']>;
  illnessCheckRequired?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notificationEmailList?: InputMaybe<Array<OrgEmailListItemInput>>;
  quarantinedCheckRequired?: InputMaybe<Scalars['Boolean']['input']>;
  quarantinedCountries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  quarantineTravelPeriod?: InputMaybe<Scalars['Int']['input']>;
  steelToeIDDestinations: Array<InputMaybe<Scalars['ID']['input']>>;
  tpID: Scalars['ID']['input'];
};


export type MutationSetEmployerArgs = {
  payload: EmployerInput;
  tpID: Scalars['ID']['input'];
};


export type MutationSetEssenPerToRigArgs = {
  personID: Scalars['ID']['input'];
  rigID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSetFlightArgs = {
  orgID: Scalars['ID']['input'];
  payload: FlightNodeInput;
};


export type MutationSetIsnSelectionArgs = {
  _id: Scalars['ID']['input'];
  _rev: Scalars['ID']['input'];
  payload: Scalars['AWSJSON']['input'];
};


export type MutationSetLocationArgs = {
  payload: LocationInput;
  tpID: Scalars['ID']['input'];
};


export type MutationSetNonPreferredArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  organizationIds?: InputMaybe<Array<InputMaybe<NonPreferredOrganizationInput>>>;
  personIds?: InputMaybe<Array<InputMaybe<NonPreferredPersonInput>>>;
  tpID: Scalars['ID']['input'];
};


export type MutationSetPassengerIsnCheckArgs = {
  _id: Scalars['ID']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSetPassengerNoFlyCheckArgs = {
  _id: Scalars['ID']['input'];
  tpID: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationSetPaxNodeArgs = {
  payload?: InputMaybe<PaxNodeInput>;
};


export type MutationSetPersonArgs = {
  credentialMap?: InputMaybe<CredentialFormInput>;
  essentialOn?: InputMaybe<Scalars['ID']['input']>;
  orgID: Scalars['ID']['input'];
  payload: PersonInput;
  updatePersonEssentialOn?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetPersonOnWhiteListArgs = {
  deleteList?: InputMaybe<Array<SetPersonOnWhiteListDeleteAction>>;
  modifyList?: InputMaybe<Array<SetPersonOnWhiteListModifyAction>>;
};


export type MutationSetPersonSetArgs = {
  payload: PersonSetInput;
};


export type MutationSetPilotArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<EmailInput>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<PhoneInput>;
  tpID: Scalars['ID']['input'];
};


export type MutationSetRemoteUserAppSettingsArgs = {
  appName: Scalars['String']['input'];
  settings: Scalars['AWSJSON']['input'];
};


export type MutationSetScheduledToExpectedArgs = {
  flightID: Scalars['String']['input'];
};


export type MutationSetUserOrgNotificationEmailListArgs = {
  emailList: Array<OrgEmailListItemInput>;
};


export type MutationSetVesselArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};


export type MutationSetWhiteListArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  locationToEmployer?: InputMaybe<Array<InputMaybe<LocationToEmployerMapInput>>>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  personIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tpID: Scalars['ID']['input'];
};


export type MutationUploadFuelTakenArgs = {
  fuelTaken: FuelTakenInput;
  overwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  tpID: Scalars['String']['input'];
};

export type FlightNodeInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  aircraftID?: InputMaybe<Scalars['ID']['input']>;
  callSign?: InputMaybe<Scalars['String']['input']>;
  cgoIDList?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contractID?: InputMaybe<Scalars['ID']['input']>;
  copilotID?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  departureID?: InputMaybe<Scalars['ID']['input']>;
  desig?: InputMaybe<Scalars['String']['input']>;
  destinationID?: InputMaybe<Scalars['ID']['input']>;
  lastKnownController?: InputMaybe<Scalars['ID']['input']>;
  legs?: InputMaybe<Scalars['AWSJSON']['input']>;
  liveEditedState?: InputMaybe<Scalars['String']['input']>;
  originalFlightID?: InputMaybe<Scalars['ID']['input']>;
  paxIDList?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pilotID?: InputMaybe<Scalars['ID']['input']>;
  scheduledFlightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  sequence?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<FlightNodeInputState>;
  tpID: Scalars['ID']['input'];
};

export enum FlightNodeInputState {
  ACTIVE = 'ACTIVE'
}

export type ApproveDispatchFlightHistoryInput = {
  _id: Scalars['ID']['input'];
  ata: Scalars['String']['input'];
  ato: Scalars['String']['input'];
  contractID: Scalars['ID']['input'];
  coPilotID?: InputMaybe<Scalars['ID']['input']>;
  departingID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  pilotID?: InputMaybe<Scalars['ID']['input']>;
  pob: Scalars['Int']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
};

export type ApproveDispatchFlightHistoryResponse = {
  __typename?: 'ApproveDispatchFlightHistoryResponse';
  changedOrAdded?: Maybe<Array<DispatchHistory>>;
  deletedIDs?: Maybe<Array<Scalars['ID']['output']>>;
};

export type DispatchHistory = Entity & {
  __typename?: 'DispatchHistory';
  _id: Scalars['ID']['output'];
  _r_latestRemark?: Maybe<DispatchRemark>;
  _rev?: Maybe<Scalars['ID']['output']>;
  aircraftDoc?: Maybe<Aircraft>;
  aircraftID?: Maybe<Scalars['ID']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  ata?: Maybe<Scalars['String']['output']>;
  ato?: Maybe<Scalars['String']['output']>;
  checkTime?: Maybe<Scalars['String']['output']>;
  classType?: Maybe<Scalars['String']['output']>;
  contractDoc?: Maybe<Contract>;
  contractID?: Maybe<Scalars['ID']['output']>;
  coPilotDoc?: Maybe<Pilot>;
  coPilotID?: Maybe<Scalars['ID']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  departingDoc?: Maybe<Location>;
  departingID?: Maybe<Scalars['ID']['output']>;
  destinationDoc?: Maybe<Location>;
  destinationID?: Maybe<Scalars['ID']['output']>;
  dispatchDoc?: Maybe<Dispatch>;
  enRouteCheck?: Maybe<Scalars['Int']['output']>;
  eta?: Maybe<Scalars['String']['output']>;
  fuel?: Maybe<Scalars['Int']['output']>;
  fuelTaken?: Maybe<Scalars['AWSJSON']['output']>;
  getTzAbbr: Scalars['String']['output'];
  getTzName: Scalars['String']['output'];
  lastKnownController?: Maybe<Scalars['ID']['output']>;
  lastKnownControllerDoc?: Maybe<Location>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pilotDoc?: Maybe<Pilot>;
  pilotID?: Maybe<Scalars['ID']['output']>;
  pob?: Maybe<Scalars['Int']['output']>;
  remark?: Maybe<Array<DispatchRemark>>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  specialsAckd?: Maybe<Scalars['AWSJSON']['output']>;
  status?: Maybe<DispatchStatus>;
  tpDoc?: Maybe<Transporter>;
  tpID?: Maybe<Scalars['ID']['output']>;
};

export type BulkDeletePersonCov19VaxRecordInput = {
  order: Scalars['Int']['input'];
  personID: Scalars['String']['input'];
};

export type BulkUpdatePersonCov19VaxRecordInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  customerID?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['String']['input'];
  employerID?: InputMaybe<Scalars['String']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  personID: Scalars['String']['input'];
  personName: Scalars['String']['input'];
};

export type CheckInPaxDocksPayload = {
  bagCount?: InputMaybe<Scalars['Int']['input']>;
  bagWeight?: InputMaybe<Scalars['Int']['input']>;
  contractID?: InputMaybe<Scalars['ID']['input']>;
  credentials: CredentialFormInput;
  currentCarrierID?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  dob: Scalars['AWSDate']['input'];
  employerID: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  lastKnownController?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  paxWeight?: InputMaybe<Scalars['Int']['input']>;
  personID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<Scalars['String']['input']>;
};

export type CredentialFormInput = {
  EBS_CARD?: InputMaybe<CredentialFormItemInput>;
  HUET_CARD?: InputMaybe<CredentialFormItemInput>;
  ISN?: InputMaybe<CredentialFormItemInput>;
  PASSPORT?: InputMaybe<CredentialFormItemInput>;
  PASSPORT_CARD?: InputMaybe<CredentialFormItemInput>;
  SAFETY_CARD?: InputMaybe<CredentialFormItemInput>;
  TWIC?: InputMaybe<CredentialFormItemInput>;
  US_DRIVERS_LICENSE?: InputMaybe<CredentialFormItemInput>;
};

export type CredentialFormItemInput = {
  expiration?: InputMaybe<Scalars['AWSDate']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type CheckInPaxFlightPayload = {
  bagCount?: InputMaybe<Scalars['Int']['input']>;
  bagWeight?: InputMaybe<Scalars['Int']['input']>;
  contractID?: InputMaybe<Scalars['ID']['input']>;
  credentials: CredentialFormInput;
  currentCarrierID?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  dob: Scalars['AWSDate']['input'];
  employerID: Scalars['ID']['input'];
  firstName: Scalars['String']['input'];
  lastKnownController?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  paxWeight?: InputMaybe<Scalars['Int']['input']>;
  personID?: InputMaybe<Scalars['ID']['input']>;
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteDispatchFlightHistoryResponse = {
  __typename?: 'DeleteDispatchFlightHistoryResponse';
  deletedIDs?: Maybe<Array<Scalars['ID']['output']>>;
};

export type FuelTakenInput = {
  aircraftId?: InputMaybe<Scalars['ID']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  contractId?: InputMaybe<Scalars['ID']['input']>;
  dateTimeTaken: Scalars['String']['input'];
  flightIdName?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  psid?: InputMaybe<Scalars['Float']['input']>;
};

export type FuelTaken = {
  __typename?: 'FuelTaken';
  aircraftId?: Maybe<Scalars['ID']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  contractId?: Maybe<Scalars['ID']['output']>;
  dateTimeTaken: Scalars['String']['output'];
  flightIdName?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  psid?: Maybe<Scalars['Float']['output']>;
};

export type ExportReportColumnSetInput = {
  columns: Array<ExportReportColumnInput>;
  dataSetName?: InputMaybe<Scalars['String']['input']>;
  dataSource?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  setName?: InputMaybe<Scalars['String']['input']>;
  totals?: InputMaybe<Array<ExportReportTotalsInput>>;
  useDataFieldAsDataSetNamePrefix?: InputMaybe<Scalars['String']['input']>;
};

export type ExportReportColumnInput = {
  format?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type ExportReportTotalsInput = {
  col_key: Scalars['String']['input'];
  summary_key: Scalars['String']['input'];
};

export type ScheduledInboundCargoInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['String']['input']>;
  approvedBy?: InputMaybe<Scalars['String']['input']>;
  attentionTo: Scalars['String']['input'];
  classType?: InputMaybe<Scalars['String']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  groundPickup?: InputMaybe<Scalars['Boolean']['input']>;
  hazmat?: InputMaybe<Scalars['Boolean']['input']>;
  hazmatUnCode?: InputMaybe<Scalars['String']['input']>;
  initials: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  receivedLocationID: Scalars['ID']['input'];
  scheduledOrder?: InputMaybe<Scalars['Int']['input']>;
  tpID?: InputMaybe<Scalars['ID']['input']>;
  transitType?: InputMaybe<TransitType>;
  weight: Scalars['Int']['input'];
};

export type PersonScheduleInput = {
  bagCount: Scalars['Int']['input'];
  bagWeight: Scalars['Int']['input'];
  chargeCode?: InputMaybe<Scalars['String']['input']>;
  paxWeight: Scalars['Int']['input'];
  personID: Scalars['ID']['input'];
  scheduledOrder: Scalars['Int']['input'];
  transitType: TransitType;
};

export type DeletedScheduledEntityInput = {
  _id: Scalars['ID']['input'];
  _rev: Scalars['String']['input'];
  classType: Scalars['String']['input'];
};

export type ScheduledCargoInput_V2 = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['String']['input']>;
  approvedBy?: InputMaybe<Scalars['String']['input']>;
  attentionTo: Scalars['String']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  hazmat?: InputMaybe<Scalars['Boolean']['input']>;
  hazmatUnCode?: InputMaybe<Scalars['String']['input']>;
  initials: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  notesForPilot?: InputMaybe<Scalars['String']['input']>;
  scheduledOrder: Scalars['Int']['input'];
  transitType: TransitType;
  weight: Scalars['Int']['input'];
};

export type ScheduledPassengerInput_V2 = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['String']['input']>;
  bagCount: Scalars['Int']['input'];
  bagWeight: Scalars['Int']['input'];
  chargeCode?: InputMaybe<Scalars['String']['input']>;
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  notesForPilot?: InputMaybe<Scalars['String']['input']>;
  paxWeight: Scalars['Int']['input'];
  personID: Scalars['ID']['input'];
  scheduledOrder: Scalars['Int']['input'];
  transitType: TransitType;
};

export type ScheduledPassengerInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['String']['input']>;
  bagCount: Scalars['Int']['input'];
  bagWeight: Scalars['Int']['input'];
  chargeCode?: InputMaybe<Scalars['String']['input']>;
  paxWeight: Scalars['Int']['input'];
  personID: Scalars['ID']['input'];
  scheduledOrder?: InputMaybe<Scalars['Int']['input']>;
  transitType: Scalars['String']['input'];
};

export type CargoNodeInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  approvedBy?: InputMaybe<Scalars['String']['input']>;
  assetID?: InputMaybe<Scalars['String']['input']>;
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  assignedLegSet?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  attentionTo?: InputMaybe<Scalars['String']['input']>;
  cargoType?: InputMaybe<Scalars['String']['input']>;
  checkedIn?: InputMaybe<Scalars['Boolean']['input']>;
  checkedInTime?: InputMaybe<Scalars['AWSDateTime']['input']>;
  checkInType?: InputMaybe<Scalars['String']['input']>;
  classType?: InputMaybe<Scalars['String']['input']>;
  contractID?: InputMaybe<Scalars['ID']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  createdTs?: InputMaybe<Scalars['AWSDateTime']['input']>;
  currentCarrierID?: InputMaybe<Scalars['ID']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  deliveredBy?: InputMaybe<Scalars['String']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  destinationID?: InputMaybe<Scalars['ID']['input']>;
  firstCheckInType?: InputMaybe<Scalars['String']['input']>;
  groundPickup?: InputMaybe<Scalars['Boolean']['input']>;
  hazmat?: InputMaybe<Scalars['Boolean']['input']>;
  hazmatUnCode?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  lastKnownController?: InputMaybe<Scalars['String']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  modifiedTs?: InputMaybe<Scalars['AWSDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nodeState?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  receivedLocation?: InputMaybe<Scalars['ID']['input']>;
  receivedLocationID?: InputMaybe<Scalars['ID']['input']>;
  scheduledFlightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  scheduledGroup?: InputMaybe<Scalars['String']['input']>;
  scheduledOrder?: InputMaybe<Scalars['Int']['input']>;
  specialStatus?: InputMaybe<Scalars['String']['input']>;
  tpID?: InputMaybe<Scalars['ID']['input']>;
  transitType?: InputMaybe<TransitType>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type OrgEmailListItemInput = {
  email: Scalars['String']['input'];
  options?: InputMaybe<OrgEmailListItemOptionsInput>;
};

export type OrgEmailListItemOptionsInput = {
  optIn?: InputMaybe<Array<Scalars['String']['input']>>;
  optInAll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmployerInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationEmailList?: InputMaybe<Array<OrgEmailListItemInput>>;
};

export type LocationInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  block?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
  hasDataflytHardware?: InputMaybe<Scalars['String']['input']>;
  isOneOff?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerOrg?: InputMaybe<Scalars['String']['input']>;
  twicRequired?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type NonPreferredOrganizationInput = {
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  id: Scalars['ID']['input'];
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type NonPreferredPersonInput = {
  endDate?: InputMaybe<Scalars['AWSDate']['input']>;
  id: Scalars['ID']['input'];
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['AWSDate']['input']>;
};

export type PaxNodeInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  bagCount?: InputMaybe<Scalars['Int']['input']>;
  bagWeight?: InputMaybe<Scalars['Int']['input']>;
  chargeCode?: InputMaybe<Scalars['String']['input']>;
  checkedIn?: InputMaybe<Scalars['Boolean']['input']>;
  checkInType?: InputMaybe<Scalars['String']['input']>;
  contractID?: InputMaybe<Scalars['ID']['input']>;
  currentCarrierID?: InputMaybe<Scalars['ID']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  departureID?: InputMaybe<Scalars['ID']['input']>;
  destinationID?: InputMaybe<Scalars['ID']['input']>;
  employerID?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastIsnResultWithHeader?: InputMaybe<Scalars['AWSJSON']['input']>;
  lastKnownController?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nodeState?: InputMaybe<Scalars['String']['input']>;
  paxWeight?: InputMaybe<Scalars['Int']['input']>;
  personID?: InputMaybe<Scalars['ID']['input']>;
  scheduledFlightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  scheduledGroup?: InputMaybe<Scalars['String']['input']>;
  scheduledOrder?: InputMaybe<Scalars['Int']['input']>;
  tpID: Scalars['ID']['input'];
  transitType?: InputMaybe<TransitType>;
};

export type PersonInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  contactInfo?: InputMaybe<ContactInfoInput>;
  contractID?: InputMaybe<Scalars['ID']['input']>;
  customerID?: InputMaybe<Scalars['ID']['input']>;
  dob?: InputMaybe<Scalars['AWSDate']['input']>;
  emergencyContactInfo?: InputMaybe<ContactInfoInput>;
  employerID?: InputMaybe<Scalars['ID']['input']>;
  extraBroadState?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nation?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['AWSJSON']['input']>>>;
  vip?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactInfoInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<EmailInput>;
  name?: InputMaybe<NameInput>;
  phone?: InputMaybe<PhoneInput>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type EmailInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EmailType>;
};

export type PhoneInput = {
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PhoneType>;
};

export type SetPersonOnWhiteListDeleteAction = {
  personID: Scalars['ID']['input'];
};

export type SetPersonOnWhiteListModifyAction = {
  nonComplyBypass_COMPANYGRADE?: InputMaybe<NonComplyBypassInput>;
  nonComplyBypass_DRUGANDALCOHOL?: InputMaybe<NonComplyBypassInput>;
  nonComplyBypass_TRAINING?: InputMaybe<NonComplyBypassInput>;
  personID: Scalars['ID']['input'];
};

export type NonComplyBypassInput = {
  expiresOn?: InputMaybe<Scalars['AWSDate']['input']>;
  reason: Scalars['String']['input'];
};

export type PersonSetInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  customerID: Scalars['ID']['input'];
  departureID: Scalars['ID']['input'];
  destinationID: Scalars['ID']['input'];
  inbound: Array<InputMaybe<Scalars['ID']['input']>>;
  lastScheduledFlightDate?: InputMaybe<Scalars['AWSDate']['input']>;
  name: Scalars['String']['input'];
  outbound: Array<InputMaybe<Scalars['ID']['input']>>;
  owner: Scalars['String']['input'];
  tpID: Scalars['ID']['input'];
};

export type LocationToEmployerMapInput = {
  employerIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationID: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  updatePax?: Maybe<PaxNode>;
};

export type BulkDocsResult = {
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ok: Scalars['Boolean']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  rev?: Maybe<Scalars['String']['output']>;
};

export type ContractInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  activate: Scalars['Boolean']['input'];
  customerID: Scalars['ID']['input'];
  endDate: Scalars['AWSDate']['input'];
  locationID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['AWSDate']['input'];
  tpID: Scalars['ID']['input'];
};

export type PersonCov19VaxRecordInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  customerID: Scalars['String']['input'];
  customerName: Scalars['String']['input'];
  date?: InputMaybe<Scalars['String']['input']>;
  employerID: Scalars['String']['input'];
  employerName: Scalars['String']['input'];
  label: Scalars['String']['input'];
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  personID: Scalars['String']['input'];
  personName?: InputMaybe<Scalars['String']['input']>;
};

export type PilotInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _rev?: InputMaybe<Scalars['ID']['input']>;
  name: NameInput;
  tpID: Scalars['ID']['input'];
};

export type Request = {
  __typename?: 'Request';
  _id: Scalars['ID']['output'];
  _rev: Scalars['String']['output'];
  classType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdTs?: Maybe<Scalars['AWSDateTime']['output']>;
  expiration?: Maybe<Scalars['AWSDate']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTs?: Maybe<Scalars['AWSDateTime']['output']>;
  personID?: Maybe<Person>;
  type?: Maybe<CredentialType>;
};

export type ScheduledPaxCgoGroupV1 = {
  __typename?: 'ScheduledPaxCgoGroupV1';
  cgoInbound?: Maybe<Scalars['Int']['output']>;
  cgoInboundOnFlight?: Maybe<Scalars['Int']['output']>;
  cgoOutbound?: Maybe<Scalars['Int']['output']>;
  cgoOutboundOnFlight?: Maybe<Scalars['Int']['output']>;
  cgoTransfer?: Maybe<Scalars['Int']['output']>;
  cgoTransferOnFlight?: Maybe<Scalars['Int']['output']>;
  customerID?: Maybe<Scalars['ID']['output']>;
  departureID?: Maybe<Location>;
  destinationID?: Maybe<Location>;
  lastKnownController?: Maybe<Location>;
  paxInbound?: Maybe<Scalars['Int']['output']>;
  paxInboundOnFlight?: Maybe<Scalars['Int']['output']>;
  paxOutbound?: Maybe<Scalars['Int']['output']>;
  paxOutboundOnFlight?: Maybe<Scalars['Int']['output']>;
  paxTransfer?: Maybe<Scalars['Int']['output']>;
  paxTransferOnFlight?: Maybe<Scalars['Int']['output']>;
  scheduledFlightDate?: Maybe<Scalars['AWSDate']['output']>;
  scheduledGroup?: Maybe<Scalars['String']['output']>;
  tpID?: Maybe<Scalars['ID']['output']>;
};

export type SchedulePersonsResult = BulkDocsResult & {
  __typename?: 'SchedulePersonsResult';
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ok: Scalars['Boolean']['output'];
  personID?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  rev?: Maybe<Scalars['String']['output']>;
  transitType?: Maybe<TransitType>;
};

export type Section = {
  __typename?: 'Section';
  name?: Maybe<Scalars['String']['output']>;
  personID?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<Maybe<Section>>>;
};

export type SkyRouterFlightTrackRow = {
  __typename?: 'SkyRouterFlightTrackRow';
  altitide?: Maybe<Scalars['Float']['output']>;
  dateAquisDate?: Maybe<Scalars['String']['output']>;
  dateAquisTIme?: Maybe<Scalars['String']['output']>;
  DOP?: Maybe<Scalars['Float']['output']>;
  heading?: Maybe<Scalars['Float']['output']>;
  imeiNumber?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  receiverStatus?: Maybe<Scalars['Float']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  reportType?: Maybe<Scalars['String']['output']>;
  sysDate?: Maybe<Scalars['String']['output']>;
  sysTime?: Maybe<Scalars['String']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
  velocity?: Maybe<Scalars['Float']['output']>;
};

export type XxxCouchDbInfo = {
  __typename?: 'xxxCouchDbInfo';
  db_name: Scalars['String']['output'];
};

export type XxxLocationRow = {
  __typename?: 'xxxLocationRow';
  key: Array<Maybe<Scalars['AWSJSON']['output']>>;
  value: Scalars['Int']['output'];
};

export type XxxLocationRows = {
  __typename?: 'xxxLocationRows';
  rows?: Maybe<Array<Maybe<XxxLocationRow>>>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  Carrier: ( BoatNode ) | ( FlightNode );
  OrganizationTypes: ( Customer ) | ( Employer ) | ( Transporter );
  PaxNodeCgoNodeUnion: ( CargoNode ) | ( PaxNode );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  Entity: ( WebUserSettings ) | ( Contract ) | ( Location ) | ( Rig ) | ( Customer ) | ( Employer ) | ( Transporter ) | ( Aircraft ) | ( BoatNode ) | ( CargoNode ) | ( FlightNode ) | ( Pilot ) | ( PaxNode ) | ( Credential ) | ( Person ) | ( Vessel ) | ( DocksCargo ) | ( Asset ) | ( Omit<PobNode, 'currentCarrierID'> & { currentCarrierID?: Maybe<_RefType['Carrier']> } ) | ( Dispatch ) | ( HistoryFlightNode ) | ( HistoryAircraft ) | ( HistoryTransporter ) | ( HistoryCargoNode ) | ( HistoryContract ) | ( HistoryLocation ) | ( HistoryCustomer ) | ( HistoryEmployer ) | ( HistoryPilot ) | ( HistoryPaxNode ) | ( HistoryCredential ) | ( HistoryPerson ) | ( Omit<NonPreferred, 'organizationIds'> & { organizationIds?: Maybe<Array<Maybe<_RefType['NonPreferredOrganization']>>> } ) | ( Omit<WhiteList, 'organizationIds'> & { organizationIds?: Maybe<Array<Maybe<_RefType['OrganizationTypes']>>> } ) | ( Report ) | ( DispatchHistory );
  OrganizationInterface: ( Organization ) | ( Customer ) | ( Employer ) | ( Transporter ) | ( HistoryTransporter ) | ( HistoryOrganization ) | ( HistoryCustomer ) | ( HistoryEmployer );
  BulkDocsResult: ( SchedulePersonsResult );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  CognitoUser: ResolverTypeWrapper<CognitoUser>;
  CognitoUserApprovalStatusEnum: CognitoUserApprovalStatusEnum;
  CognitoUserAttribute: ResolverTypeWrapper<CognitoUserAttribute>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CognitoUserGroup: ResolverTypeWrapper<CognitoUserGroup>;
  DFCognitoUserMappedAtrributes: ResolverTypeWrapper<DfCognitoUserMappedAtrributes>;
  WebUserSettings: ResolverTypeWrapper<WebUserSettings>;
  Entity: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Entity']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']['output']>;
  WebUserAppSettings: ResolverTypeWrapper<WebUserAppSettings>;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']['output']>;
  Contract: ResolverTypeWrapper<Contract>;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationInterface: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OrganizationInterface']>;
  ManifestPrintoutType: ResolverTypeWrapper<ManifestPrintoutType>;
  ManifestPrintoutLayout: ResolverTypeWrapper<ManifestPrintoutLayout>;
  AWSURL: ResolverTypeWrapper<Scalars['AWSURL']['output']>;
  OrganizationType: OrganizationType;
  AWSDate: ResolverTypeWrapper<Scalars['AWSDate']['output']>;
  Location: ResolverTypeWrapper<Location>;
  Rig: ResolverTypeWrapper<Rig>;
  Customer: ResolverTypeWrapper<Customer>;
  EmployerEvaluationPercentage: ResolverTypeWrapper<EmployerEvaluationPercentage>;
  Employer: ResolverTypeWrapper<Employer>;
  OrgEmailListItem: ResolverTypeWrapper<OrgEmailListItem>;
  OrgEmailListItemOptions: ResolverTypeWrapper<OrgEmailListItemOptions>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Transporter: ResolverTypeWrapper<Transporter>;
  LocationCoordinates: ResolverTypeWrapper<LocationCoordinates>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  LocationType: LocationType;
  AdminCognitoListUserGroupsResult: ResolverTypeWrapper<AdminCognitoListUserGroupsResult>;
  AdminCognitoListUsersResult: ResolverTypeWrapper<AdminCognitoListUsersResult>;
  AircraftFilterInput: AircraftFilterInput;
  MangoQueryResult: ResolverTypeWrapper<Omit<MangoQueryResult, 'docs'> & { docs?: Maybe<Array<Maybe<ResolversTypes['Entity']>>> }>;
  MangoExecutionStats: ResolverTypeWrapper<MangoExecutionStats>;
  BoatsFilterInput: BoatsFilterInput;
  CargoFilterInput: CargoFilterInput;
  TransitType: TransitType;
  ContractsFilterInput: ContractsFilterInput;
  CredentialFilterInput: CredentialFilterInput;
  EmployerFilterInput: EmployerFilterInput;
  FlightsFilterInput: FlightsFilterInput;
  Aircraft: ResolverTypeWrapper<Aircraft>;
  BoatNode: ResolverTypeWrapper<BoatNode>;
  BoatCrew: ResolverTypeWrapper<BoatCrew>;
  Authorization: ResolverTypeWrapper<Authorization>;
  BrdRestriction: ResolverTypeWrapper<BrdRestriction>;
  BrdRestrictionRestrictionType: BrdRestrictionRestrictionType;
  BrdRestrictionStatus: BrdRestrictionStatus;
  BrdRestrictionType: BrdRestrictionType;
  ContactInfo: ResolverTypeWrapper<ContactInfo>;
  Address: ResolverTypeWrapper<Address>;
  Email: ResolverTypeWrapper<Email>;
  EmailType: EmailType;
  Name: ResolverTypeWrapper<Name>;
  Phone: ResolverTypeWrapper<Phone>;
  PhoneType: PhoneType;
  IsnResultWithHeader: ResolverTypeWrapper<IsnResultWithHeader>;
  IsnQcResult: ResolverTypeWrapper<IsnQcResult>;
  IsnStatus: ResolverTypeWrapper<IsnStatus>;
  IsnQcResultProject: ResolverTypeWrapper<IsnQcResultProject>;
  CargoNode: ResolverTypeWrapper<CargoNode>;
  FlightNode: ResolverTypeWrapper<FlightNode>;
  Pilot: ResolverTypeWrapper<Pilot>;
  FlightLeg: ResolverTypeWrapper<FlightLeg>;
  PaxNode: ResolverTypeWrapper<PaxNode>;
  Credential: ResolverTypeWrapper<Credential>;
  Person: ResolverTypeWrapper<Person>;
  CredentialType: CredentialType;
  IsnCheckError: ResolverTypeWrapper<IsnCheckError>;
  ScheduledVersion: ScheduledVersion;
  LiveEditedState: LiveEditedState;
  Vessel: ResolverTypeWrapper<Vessel>;
  ViewResult: ResolverTypeWrapper<Omit<ViewResult, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['ViewResultRow']>>> }>;
  ViewResultRow: ResolverTypeWrapper<Omit<ViewResultRow, 'doc'> & { doc?: Maybe<ResolversTypes['Entity']> }>;
  DocksCargo: ResolverTypeWrapper<DocksCargo>;
  Asset: ResolverTypeWrapper<Asset>;
  CountryCodes: ResolverTypeWrapper<CountryCodes>;
  Country: ResolverTypeWrapper<Country>;
  PobNode: ResolverTypeWrapper<Omit<PobNode, 'currentCarrierID'> & { currentCarrierID?: Maybe<ResolversTypes['Carrier']> }>;
  Carrier: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Carrier']>;
  ShiftRecord: ResolverTypeWrapper<ShiftRecord>;
  Dispatch: ResolverTypeWrapper<Dispatch>;
  DispatchRemark: ResolverTypeWrapper<DispatchRemark>;
  DispatchStatus: DispatchStatus;
  HistoryFlightNode: ResolverTypeWrapper<HistoryFlightNode>;
  HistoryAircraft: ResolverTypeWrapper<HistoryAircraft>;
  HistoryTransporter: ResolverTypeWrapper<HistoryTransporter>;
  HistoryCargoNode: ResolverTypeWrapper<HistoryCargoNode>;
  HistoryContract: ResolverTypeWrapper<HistoryContract>;
  HistoryOrganization: ResolverTypeWrapper<HistoryOrganization>;
  HistoryLocation: ResolverTypeWrapper<HistoryLocation>;
  HistoryCustomer: ResolverTypeWrapper<HistoryCustomer>;
  HistoryEmployerEvaluationPercentage: ResolverTypeWrapper<HistoryEmployerEvaluationPercentage>;
  HistoryEmployer: ResolverTypeWrapper<HistoryEmployer>;
  HistoryPilot: ResolverTypeWrapper<HistoryPilot>;
  HistoryFlightLeg: ResolverTypeWrapper<HistoryFlightLeg>;
  HistoryPaxNode: ResolverTypeWrapper<HistoryPaxNode>;
  HistoryCredential: ResolverTypeWrapper<HistoryCredential>;
  HistoryPerson: ResolverTypeWrapper<HistoryPerson>;
  NonPreferred: ResolverTypeWrapper<Omit<NonPreferred, 'organizationIds'> & { organizationIds?: Maybe<Array<Maybe<ResolversTypes['NonPreferredOrganization']>>> }>;
  NonPreferredOrganization: ResolverTypeWrapper<Omit<NonPreferredOrganization, 'id'> & { id?: Maybe<ResolversTypes['OrganizationTypes']> }>;
  OrganizationTypes: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['OrganizationTypes']>;
  NonPreferredPerson: ResolverTypeWrapper<NonPreferredPerson>;
  ScheduledPaxCgoResult: ResolverTypeWrapper<Omit<ScheduledPaxCgoResult, 'combined'> & { combined?: Maybe<Array<Maybe<ResolversTypes['PaxNodeCgoNodeUnion']>>> }>;
  PaxNodeCgoNodeUnion: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PaxNodeCgoNodeUnion']>;
  PersonCov19VaxRecord: ResolverTypeWrapper<PersonCov19VaxRecord>;
  PersonSet: ResolverTypeWrapper<PersonSet>;
  ScheduledPaxCgoGroupV2: ResolverTypeWrapper<ScheduledPaxCgoGroupV2>;
  ScheduledPaxCgoGroupV2Leg: ResolverTypeWrapper<ScheduledPaxCgoGroupV2Leg>;
  ScheduledPaxNodeGroup: ResolverTypeWrapper<ScheduledPaxNodeGroup>;
  ScheduleHistoryDetail: ResolverTypeWrapper<ScheduleHistoryDetail>;
  WhiteList: ResolverTypeWrapper<Omit<WhiteList, 'organizationIds'> & { organizationIds?: Maybe<Array<Maybe<ResolversTypes['OrganizationTypes']>>> }>;
  LocationToEmployerMap: ResolverTypeWrapper<LocationToEmployerMap>;
  WhiteListPersonToNonComplyBypassMap: ResolverTypeWrapper<WhiteListPersonToNonComplyBypassMap>;
  NonComplyBypass: ResolverTypeWrapper<NonComplyBypass>;
  CognitoUserInfo: ResolverTypeWrapper<CognitoUserInfo>;
  ListAvailableNotifiersResponse: ResolverTypeWrapper<ListAvailableNotifiersResponse>;
  AvailableNotifier: ResolverTypeWrapper<AvailableNotifier>;
  ScheduleHistoryListItem: ResolverTypeWrapper<ScheduleHistoryListItem>;
  LocationsFilterInput: LocationsFilterInput;
  FilterByKeyValueInput: FilterByKeyValueInput;
  OrganizationsFilterInput: OrganizationsFilterInput;
  PassengersFilterInput: PassengersFilterInput;
  PersonFilterInput: PersonFilterInput;
  PilotsFilterInput: PilotsFilterInput;
  NameInput: NameInput;
  Report: ResolverTypeWrapper<Report>;
  CreationMetaData: ResolverTypeWrapper<CreationMetaData>;
  ReportCriteria: ResolverTypeWrapper<ReportCriteria>;
  ReportError: ResolverTypeWrapper<ReportError>;
  ReportResult: ResolverTypeWrapper<ReportResult>;
  ReportStatus: ReportStatus;
  ReportType: ReportType;
  ReportsFilterInput: ReportsFilterInput;
  ReportCriteriaInput: ReportCriteriaInput;
  VesselsFilterInput: VesselsFilterInput;
  Mutation: ResolverTypeWrapper<{}>;
  FlightNodeInput: FlightNodeInput;
  FlightNodeInputState: FlightNodeInputState;
  ApproveDispatchFlightHistoryInput: ApproveDispatchFlightHistoryInput;
  ApproveDispatchFlightHistoryResponse: ResolverTypeWrapper<ApproveDispatchFlightHistoryResponse>;
  DispatchHistory: ResolverTypeWrapper<DispatchHistory>;
  BulkDeletePersonCov19VaxRecordInput: BulkDeletePersonCov19VaxRecordInput;
  BulkUpdatePersonCov19VaxRecordInput: BulkUpdatePersonCov19VaxRecordInput;
  CheckInPaxDocksPayload: CheckInPaxDocksPayload;
  CredentialFormInput: CredentialFormInput;
  CredentialFormItemInput: CredentialFormItemInput;
  CheckInPaxFlightPayload: CheckInPaxFlightPayload;
  DeleteDispatchFlightHistoryResponse: ResolverTypeWrapper<DeleteDispatchFlightHistoryResponse>;
  FuelTakenInput: FuelTakenInput;
  FuelTaken: ResolverTypeWrapper<FuelTaken>;
  ExportReportColumnSetInput: ExportReportColumnSetInput;
  ExportReportColumnInput: ExportReportColumnInput;
  ExportReportTotalsInput: ExportReportTotalsInput;
  ScheduledInboundCargoInput: ScheduledInboundCargoInput;
  PersonScheduleInput: PersonScheduleInput;
  DeletedScheduledEntityInput: DeletedScheduledEntityInput;
  ScheduledCargoInput_V2: ScheduledCargoInput_V2;
  ScheduledPassengerInput_V2: ScheduledPassengerInput_V2;
  ScheduledPassengerInput: ScheduledPassengerInput;
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']['output']>;
  AWSPhone: ResolverTypeWrapper<Scalars['AWSPhone']['output']>;
  CargoNodeInput: CargoNodeInput;
  OrgEmailListItemInput: OrgEmailListItemInput;
  OrgEmailListItemOptionsInput: OrgEmailListItemOptionsInput;
  EmployerInput: EmployerInput;
  LocationInput: LocationInput;
  NonPreferredOrganizationInput: NonPreferredOrganizationInput;
  NonPreferredPersonInput: NonPreferredPersonInput;
  PaxNodeInput: PaxNodeInput;
  PersonInput: PersonInput;
  ContactInfoInput: ContactInfoInput;
  AddressInput: AddressInput;
  EmailInput: EmailInput;
  PhoneInput: PhoneInput;
  SetPersonOnWhiteListDeleteAction: SetPersonOnWhiteListDeleteAction;
  SetPersonOnWhiteListModifyAction: SetPersonOnWhiteListModifyAction;
  NonComplyBypassInput: NonComplyBypassInput;
  PersonSetInput: PersonSetInput;
  LocationToEmployerMapInput: LocationToEmployerMapInput;
  Subscription: ResolverTypeWrapper<{}>;
  AWSIPAddress: ResolverTypeWrapper<Scalars['AWSIPAddress']['output']>;
  AWSTime: ResolverTypeWrapper<Scalars['AWSTime']['output']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']['output']>;
  BulkDocsResult: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BulkDocsResult']>;
  ContractInput: ContractInput;
  PersonCov19VaxRecordInput: PersonCov19VaxRecordInput;
  PilotInput: PilotInput;
  Request: ResolverTypeWrapper<Request>;
  ScheduledPaxCgoGroupV1: ResolverTypeWrapper<ScheduledPaxCgoGroupV1>;
  SchedulePersonsResult: ResolverTypeWrapper<SchedulePersonsResult>;
  Section: ResolverTypeWrapper<Section>;
  SkyRouterFlightTrackRow: ResolverTypeWrapper<SkyRouterFlightTrackRow>;
  xxxCouchDbInfo: ResolverTypeWrapper<XxxCouchDbInfo>;
  xxxLocationRow: ResolverTypeWrapper<XxxLocationRow>;
  xxxLocationRows: ResolverTypeWrapper<XxxLocationRows>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  String: Scalars['String']['output'];
  CognitoUser: CognitoUser;
  CognitoUserAttribute: CognitoUserAttribute;
  Boolean: Scalars['Boolean']['output'];
  CognitoUserGroup: CognitoUserGroup;
  DFCognitoUserMappedAtrributes: DfCognitoUserMappedAtrributes;
  WebUserSettings: WebUserSettings;
  Entity: ResolversInterfaceTypes<ResolversParentTypes>['Entity'];
  ID: Scalars['ID']['output'];
  AWSDateTime: Scalars['AWSDateTime']['output'];
  WebUserAppSettings: WebUserAppSettings;
  AWSJSON: Scalars['AWSJSON']['output'];
  Contract: Contract;
  Organization: Organization;
  OrganizationInterface: ResolversInterfaceTypes<ResolversParentTypes>['OrganizationInterface'];
  ManifestPrintoutType: ManifestPrintoutType;
  ManifestPrintoutLayout: ManifestPrintoutLayout;
  AWSURL: Scalars['AWSURL']['output'];
  AWSDate: Scalars['AWSDate']['output'];
  Location: Location;
  Rig: Rig;
  Customer: Customer;
  EmployerEvaluationPercentage: EmployerEvaluationPercentage;
  Employer: Employer;
  OrgEmailListItem: OrgEmailListItem;
  OrgEmailListItemOptions: OrgEmailListItemOptions;
  Int: Scalars['Int']['output'];
  Transporter: Transporter;
  LocationCoordinates: LocationCoordinates;
  Float: Scalars['Float']['output'];
  AdminCognitoListUserGroupsResult: AdminCognitoListUserGroupsResult;
  AdminCognitoListUsersResult: AdminCognitoListUsersResult;
  AircraftFilterInput: AircraftFilterInput;
  MangoQueryResult: Omit<MangoQueryResult, 'docs'> & { docs?: Maybe<Array<Maybe<ResolversParentTypes['Entity']>>> };
  MangoExecutionStats: MangoExecutionStats;
  BoatsFilterInput: BoatsFilterInput;
  CargoFilterInput: CargoFilterInput;
  ContractsFilterInput: ContractsFilterInput;
  CredentialFilterInput: CredentialFilterInput;
  EmployerFilterInput: EmployerFilterInput;
  FlightsFilterInput: FlightsFilterInput;
  Aircraft: Aircraft;
  BoatNode: BoatNode;
  BoatCrew: BoatCrew;
  Authorization: Authorization;
  BrdRestriction: BrdRestriction;
  ContactInfo: ContactInfo;
  Address: Address;
  Email: Email;
  Name: Name;
  Phone: Phone;
  IsnResultWithHeader: IsnResultWithHeader;
  IsnQcResult: IsnQcResult;
  IsnStatus: IsnStatus;
  IsnQcResultProject: IsnQcResultProject;
  CargoNode: CargoNode;
  FlightNode: FlightNode;
  Pilot: Pilot;
  FlightLeg: FlightLeg;
  PaxNode: PaxNode;
  Credential: Credential;
  Person: Person;
  IsnCheckError: IsnCheckError;
  Vessel: Vessel;
  ViewResult: Omit<ViewResult, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['ViewResultRow']>>> };
  ViewResultRow: Omit<ViewResultRow, 'doc'> & { doc?: Maybe<ResolversParentTypes['Entity']> };
  DocksCargo: DocksCargo;
  Asset: Asset;
  CountryCodes: CountryCodes;
  Country: Country;
  PobNode: Omit<PobNode, 'currentCarrierID'> & { currentCarrierID?: Maybe<ResolversParentTypes['Carrier']> };
  Carrier: ResolversUnionTypes<ResolversParentTypes>['Carrier'];
  ShiftRecord: ShiftRecord;
  Dispatch: Dispatch;
  DispatchRemark: DispatchRemark;
  HistoryFlightNode: HistoryFlightNode;
  HistoryAircraft: HistoryAircraft;
  HistoryTransporter: HistoryTransporter;
  HistoryCargoNode: HistoryCargoNode;
  HistoryContract: HistoryContract;
  HistoryOrganization: HistoryOrganization;
  HistoryLocation: HistoryLocation;
  HistoryCustomer: HistoryCustomer;
  HistoryEmployerEvaluationPercentage: HistoryEmployerEvaluationPercentage;
  HistoryEmployer: HistoryEmployer;
  HistoryPilot: HistoryPilot;
  HistoryFlightLeg: HistoryFlightLeg;
  HistoryPaxNode: HistoryPaxNode;
  HistoryCredential: HistoryCredential;
  HistoryPerson: HistoryPerson;
  NonPreferred: Omit<NonPreferred, 'organizationIds'> & { organizationIds?: Maybe<Array<Maybe<ResolversParentTypes['NonPreferredOrganization']>>> };
  NonPreferredOrganization: Omit<NonPreferredOrganization, 'id'> & { id?: Maybe<ResolversParentTypes['OrganizationTypes']> };
  OrganizationTypes: ResolversUnionTypes<ResolversParentTypes>['OrganizationTypes'];
  NonPreferredPerson: NonPreferredPerson;
  ScheduledPaxCgoResult: Omit<ScheduledPaxCgoResult, 'combined'> & { combined?: Maybe<Array<Maybe<ResolversParentTypes['PaxNodeCgoNodeUnion']>>> };
  PaxNodeCgoNodeUnion: ResolversUnionTypes<ResolversParentTypes>['PaxNodeCgoNodeUnion'];
  PersonCov19VaxRecord: PersonCov19VaxRecord;
  PersonSet: PersonSet;
  ScheduledPaxCgoGroupV2: ScheduledPaxCgoGroupV2;
  ScheduledPaxCgoGroupV2Leg: ScheduledPaxCgoGroupV2Leg;
  ScheduledPaxNodeGroup: ScheduledPaxNodeGroup;
  ScheduleHistoryDetail: ScheduleHistoryDetail;
  WhiteList: Omit<WhiteList, 'organizationIds'> & { organizationIds?: Maybe<Array<Maybe<ResolversParentTypes['OrganizationTypes']>>> };
  LocationToEmployerMap: LocationToEmployerMap;
  WhiteListPersonToNonComplyBypassMap: WhiteListPersonToNonComplyBypassMap;
  NonComplyBypass: NonComplyBypass;
  CognitoUserInfo: CognitoUserInfo;
  ListAvailableNotifiersResponse: ListAvailableNotifiersResponse;
  AvailableNotifier: AvailableNotifier;
  ScheduleHistoryListItem: ScheduleHistoryListItem;
  LocationsFilterInput: LocationsFilterInput;
  FilterByKeyValueInput: FilterByKeyValueInput;
  OrganizationsFilterInput: OrganizationsFilterInput;
  PassengersFilterInput: PassengersFilterInput;
  PersonFilterInput: PersonFilterInput;
  PilotsFilterInput: PilotsFilterInput;
  NameInput: NameInput;
  Report: Report;
  CreationMetaData: CreationMetaData;
  ReportCriteria: ReportCriteria;
  ReportError: ReportError;
  ReportResult: ReportResult;
  ReportsFilterInput: ReportsFilterInput;
  ReportCriteriaInput: ReportCriteriaInput;
  VesselsFilterInput: VesselsFilterInput;
  Mutation: {};
  FlightNodeInput: FlightNodeInput;
  ApproveDispatchFlightHistoryInput: ApproveDispatchFlightHistoryInput;
  ApproveDispatchFlightHistoryResponse: ApproveDispatchFlightHistoryResponse;
  DispatchHistory: DispatchHistory;
  BulkDeletePersonCov19VaxRecordInput: BulkDeletePersonCov19VaxRecordInput;
  BulkUpdatePersonCov19VaxRecordInput: BulkUpdatePersonCov19VaxRecordInput;
  CheckInPaxDocksPayload: CheckInPaxDocksPayload;
  CredentialFormInput: CredentialFormInput;
  CredentialFormItemInput: CredentialFormItemInput;
  CheckInPaxFlightPayload: CheckInPaxFlightPayload;
  DeleteDispatchFlightHistoryResponse: DeleteDispatchFlightHistoryResponse;
  FuelTakenInput: FuelTakenInput;
  FuelTaken: FuelTaken;
  ExportReportColumnSetInput: ExportReportColumnSetInput;
  ExportReportColumnInput: ExportReportColumnInput;
  ExportReportTotalsInput: ExportReportTotalsInput;
  ScheduledInboundCargoInput: ScheduledInboundCargoInput;
  PersonScheduleInput: PersonScheduleInput;
  DeletedScheduledEntityInput: DeletedScheduledEntityInput;
  ScheduledCargoInput_V2: ScheduledCargoInput_V2;
  ScheduledPassengerInput_V2: ScheduledPassengerInput_V2;
  ScheduledPassengerInput: ScheduledPassengerInput;
  AWSEmail: Scalars['AWSEmail']['output'];
  AWSPhone: Scalars['AWSPhone']['output'];
  CargoNodeInput: CargoNodeInput;
  OrgEmailListItemInput: OrgEmailListItemInput;
  OrgEmailListItemOptionsInput: OrgEmailListItemOptionsInput;
  EmployerInput: EmployerInput;
  LocationInput: LocationInput;
  NonPreferredOrganizationInput: NonPreferredOrganizationInput;
  NonPreferredPersonInput: NonPreferredPersonInput;
  PaxNodeInput: PaxNodeInput;
  PersonInput: PersonInput;
  ContactInfoInput: ContactInfoInput;
  AddressInput: AddressInput;
  EmailInput: EmailInput;
  PhoneInput: PhoneInput;
  SetPersonOnWhiteListDeleteAction: SetPersonOnWhiteListDeleteAction;
  SetPersonOnWhiteListModifyAction: SetPersonOnWhiteListModifyAction;
  NonComplyBypassInput: NonComplyBypassInput;
  PersonSetInput: PersonSetInput;
  LocationToEmployerMapInput: LocationToEmployerMapInput;
  Subscription: {};
  AWSIPAddress: Scalars['AWSIPAddress']['output'];
  AWSTime: Scalars['AWSTime']['output'];
  AWSTimestamp: Scalars['AWSTimestamp']['output'];
  BulkDocsResult: ResolversInterfaceTypes<ResolversParentTypes>['BulkDocsResult'];
  ContractInput: ContractInput;
  PersonCov19VaxRecordInput: PersonCov19VaxRecordInput;
  PilotInput: PilotInput;
  Request: Request;
  ScheduledPaxCgoGroupV1: ScheduledPaxCgoGroupV1;
  SchedulePersonsResult: SchedulePersonsResult;
  Section: Section;
  SkyRouterFlightTrackRow: SkyRouterFlightTrackRow;
  xxxCouchDbInfo: XxxCouchDbInfo;
  xxxLocationRow: XxxLocationRow;
  xxxLocationRows: XxxLocationRows;
};

export type Aws_Api_KeyDirectiveArgs = { };

export type Aws_Api_KeyDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Api_KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_AuthDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_AuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = { };

export type Aws_IamDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_IamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = { };

export type Aws_OidcDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_OidcDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_PublishDirectiveArgs = {
  subscriptions?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_PublishDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_PublishDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_SubscribeDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_SubscribeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DeferDirectiveArgs = { };

export type DeferDirectiveResolver<Result, Parent, ContextType = any, Args = DeferDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  AdminCognitoGetUser?: Resolver<ResolversTypes['CognitoUser'], ParentType, ContextType, RequireFields<QueryAdminCognitoGetUserArgs, 'Username'>>;
  AdminCognitoGetUserApprovalStatus?: Resolver<Maybe<ResolversTypes['CognitoUserApprovalStatusEnum']>, ParentType, ContextType, RequireFields<QueryAdminCognitoGetUserApprovalStatusArgs, 'Username'>>;
  AdminCognitoListGroups?: Resolver<Maybe<ResolversTypes['AdminCognitoListUserGroupsResult']>, ParentType, ContextType, Partial<QueryAdminCognitoListGroupsArgs>>;
  AdminCognitoListUserGroups?: Resolver<Maybe<ResolversTypes['AdminCognitoListUserGroupsResult']>, ParentType, ContextType, RequireFields<QueryAdminCognitoListUserGroupsArgs, 'username'>>;
  AdminCognitoListUsers?: Resolver<ResolversTypes['AdminCognitoListUsersResult'], ParentType, ContextType, Partial<QueryAdminCognitoListUsersArgs>>;
  aircraft?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryAircraftArgs>>;
  boats?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryBoatsArgs>>;
  cargo?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryCargoArgs>>;
  contracts?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryContractsArgs>>;
  credentials?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryCredentialsArgs>>;
  dashboardPassengers?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, RequireFields<QueryDashboardPassengersArgs, 'endDate' | 'startDate' | 'tpID'>>;
  DispatchFlights?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType>;
  DispatchHistoryFlights?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, RequireFields<QueryDispatchHistoryFlightsArgs, 'tpID'>>;
  employers?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryEmployersArgs>>;
  FlightDesigList?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryFlightDesigListArgs, 'tpID'>>;
  flights?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryFlightsArgs>>;
  FlightTailList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Aircraft']>>>, ParentType, ContextType, RequireFields<QueryFlightTailListArgs, 'tpID'>>;
  getAircraft?: Resolver<Maybe<ResolversTypes['Aircraft']>, ParentType, ContextType, RequireFields<QueryGetAircraftArgs, '_id'>>;
  getAircrafts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Aircraft']>>>, ParentType, ContextType, RequireFields<QueryGetAircraftsArgs, 'tpID'>>;
  GetAssetTrackingData?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType>;
  getBoat?: Resolver<Maybe<ResolversTypes['BoatNode']>, ParentType, ContextType, RequireFields<QueryGetBoatArgs, '_id'>>;
  getBoatCrew?: Resolver<Maybe<ResolversTypes['BoatCrew']>, ParentType, ContextType, RequireFields<QueryGetBoatCrewArgs, '_id'>>;
  getBoats?: Resolver<Maybe<Array<Maybe<ResolversTypes['BoatNode']>>>, ParentType, ContextType, RequireFields<QueryGetBoatsArgs, 'tpID'>>;
  getCargo?: Resolver<Maybe<ResolversTypes['ViewResult']>, ParentType, ContextType, RequireFields<QueryGetCargoArgs, 'tpID'>>;
  getCargoLeavingRig?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocksCargo']>>>, ParentType, ContextType, RequireFields<QueryGetCargoLeavingRigArgs, 'departureID'>>;
  getCargoNodeList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType, Partial<QueryGetCargoNodeListArgs>>;
  getCargoToRig?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocksCargo']>>>, ParentType, ContextType, RequireFields<QueryGetCargoToRigArgs, 'destinationID'>>;
  getContract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<QueryGetContractArgs, '_id'>>;
  getContracts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contract']>>>, ParentType, ContextType, RequireFields<QueryGetContractsArgs, 'orgId' | 'orgType'>>;
  getContractsByCustomer?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contract']>>>, ParentType, ContextType, RequireFields<QueryGetContractsByCustomerArgs, 'customerID'>>;
  getContractsByTransporter?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contract']>>>, ParentType, ContextType, RequireFields<QueryGetContractsByTransporterArgs, 'tpID'>>;
  getCountryCodes?: Resolver<Maybe<ResolversTypes['CountryCodes']>, ParentType, ContextType>;
  GetCovidVaxManufacturers?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  getCustomer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QueryGetCustomerArgs, '_id'>>;
  GetCustomerLocationDocs?: Resolver<Maybe<Array<Maybe<ResolversTypes['PobNode']>>>, ParentType, ContextType, RequireFields<QueryGetCustomerLocationDocsArgs, 'customerID'>>;
  getCustomers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Customer']>>>, ParentType, ContextType>;
  GetDispatchByID?: Resolver<Maybe<ResolversTypes['Dispatch']>, ParentType, ContextType, RequireFields<QueryGetDispatchByIdArgs, 'id'>>;
  GetDispatchFlightHistory?: Resolver<Maybe<Array<ResolversTypes['Dispatch']>>, ParentType, ContextType, RequireFields<QueryGetDispatchFlightHistoryArgs, 'id'>>;
  getDocksCargoOnRig?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocksCargo']>>>, ParentType, ContextType, RequireFields<QueryGetDocksCargoOnRigArgs, '_id'>>;
  getEmployer?: Resolver<ResolversTypes['Employer'], ParentType, ContextType, RequireFields<QueryGetEmployerArgs, '_id'>>;
  getEmployers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employer']>>>, ParentType, ContextType>;
  getFinalizedFlights?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, RequireFields<QueryGetFinalizedFlightsArgs, 'endDate' | 'startDate' | 'tpID'>>;
  getFlight?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType, RequireFields<QueryGetFlightArgs, '_id'>>;
  getFlightHistory?: Resolver<Maybe<ResolversTypes['HistoryFlightNode']>, ParentType, ContextType, RequireFields<QueryGetFlightHistoryArgs, '_id'>>;
  getFlights?: Resolver<Maybe<Array<Maybe<ResolversTypes['FlightNode']>>>, ParentType, ContextType, RequireFields<QueryGetFlightsArgs, 'tpID'>>;
  GetFuelTaken?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<QueryGetFuelTakenArgs, 'reportType' | 'startDateTime' | 'tpID'>>;
  getIsnCredential?: Resolver<Maybe<Array<Maybe<ResolversTypes['Credential']>>>, ParentType, ContextType, RequireFields<QueryGetIsnCredentialArgs, 'personIDs'>>;
  getLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<QueryGetLocationArgs, '_id'>>;
  getLocations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType, Partial<QueryGetLocationsArgs>>;
  getLocationsOffshore?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  getLocationsOnshore?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  getNonPreferredList?: Resolver<Maybe<ResolversTypes['NonPreferred']>, ParentType, ContextType, RequireFields<QueryGetNonPreferredListArgs, '_id'>>;
  getOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, Partial<QueryGetOrganizationArgs>>;
  getPassenger?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<QueryGetPassengerArgs, '_id'>>;
  getPassengerIsnCheck?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<QueryGetPassengerIsnCheckArgs, '_id' | 'tpID'>>;
  getPassengers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryGetPassengersArgs, 'tpID'>>;
  getPaxNodeByGroup?: Resolver<Maybe<ResolversTypes['ScheduledPaxCgoResult']>, ParentType, ContextType, RequireFields<QueryGetPaxNodeByGroupArgs, 'customerID' | 'scheduledFlightDate' | 'tpID'>>;
  getPaxNodeList?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, Partial<QueryGetPaxNodeListArgs>>;
  getPaxNodesLeavingRig?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryGetPaxNodesLeavingRigArgs, 'departureID'>>;
  getPaxNodesToRig?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryGetPaxNodesToRigArgs, 'destinationID'>>;
  GetPersonCov19VaxRecords?: Resolver<Maybe<Array<ResolversTypes['PersonCov19VaxRecord']>>, ParentType, ContextType, RequireFields<QueryGetPersonCov19VaxRecordsArgs, 'personID'>>;
  getPersonList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType, Partial<QueryGetPersonListArgs>>;
  getPersons?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType, RequireFields<QueryGetPersonsArgs, 'tpID'>>;
  getPersonsByCred?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType, RequireFields<QueryGetPersonsByCredArgs, 'search'>>;
  getPersonSet?: Resolver<Maybe<ResolversTypes['PersonSet']>, ParentType, ContextType, RequireFields<QueryGetPersonSetArgs, '_id'>>;
  getPersonSets?: Resolver<Maybe<Array<Maybe<ResolversTypes['PersonSet']>>>, ParentType, ContextType, RequireFields<QueryGetPersonSetsArgs, 'customerID' | 'tpID'>>;
  getPilot?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType, RequireFields<QueryGetPilotArgs, '_id'>>;
  getPilots?: Resolver<Maybe<Array<Maybe<ResolversTypes['Pilot']>>>, ParentType, ContextType, RequireFields<QueryGetPilotsArgs, 'tpID'>>;
  getPobNode?: Resolver<Maybe<ResolversTypes['PobNode']>, ParentType, ContextType, RequireFields<QueryGetPobNodeArgs, '_id'>>;
  getPobNodesOnRig?: Resolver<Maybe<Array<Maybe<ResolversTypes['PobNode']>>>, ParentType, ContextType, RequireFields<QueryGetPobNodesOnRigArgs, '_id'>>;
  GetRemoteUserAppSettings?: Resolver<ResolversTypes['WebUserAppSettings'], ParentType, ContextType, RequireFields<QueryGetRemoteUserAppSettingsArgs, 'appName'>>;
  GetRemoteUserSettings?: Resolver<ResolversTypes['WebUserSettings'], ParentType, ContextType>;
  getRig?: Resolver<Maybe<ResolversTypes['Rig']>, ParentType, ContextType, RequireFields<QueryGetRigArgs, '_id'>>;
  getRigs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Rig']>>>, ParentType, ContextType, Partial<QueryGetRigsArgs>>;
  getScheduledFlights?: Resolver<Maybe<Array<Maybe<ResolversTypes['FlightNode']>>>, ParentType, ContextType, RequireFields<QueryGetScheduledFlightsArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  getScheduledPassengers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryGetScheduledPassengersArgs, 'customerID' | 'departureID' | 'destinationID' | 'scheduledFlightDate' | 'scheduledGroup' | 'tpID'>>;
  GetScheduledPaxCgoGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledPaxCgoGroupV2']>>>, ParentType, ContextType, RequireFields<QueryGetScheduledPaxCgoGroupsArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  GetScheduledPaxCgoGroupsV2?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledPaxCgoGroupV2']>>>, ParentType, ContextType, RequireFields<QueryGetScheduledPaxCgoGroupsV2Args, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  GetScheduledPaxCgoV2?: Resolver<Maybe<ResolversTypes['ScheduledPaxCgoResult']>, ParentType, ContextType, RequireFields<QueryGetScheduledPaxCgoV2Args, 'customerID' | 'scheduledFlightDate' | 'scheduledGroup' | 'tpID'>>;
  getScheduledPaxInbCgo?: Resolver<Maybe<ResolversTypes['ScheduledPaxCgoResult']>, ParentType, ContextType, RequireFields<QueryGetScheduledPaxInbCgoArgs, 'customerID' | 'departureID' | 'destinationID' | 'scheduledFlightDate' | 'scheduledGroup' | 'tpID'>>;
  getScheduledPaxInbCgoGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledPaxNodeGroup']>>>, ParentType, ContextType, RequireFields<QueryGetScheduledPaxInbCgoGroupsArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  getScheduledPaxNodeGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledPaxNodeGroup']>>>, ParentType, ContextType, RequireFields<QueryGetScheduledPaxNodeGroupsArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  GetScheduleHistory?: Resolver<Maybe<ResolversTypes['ScheduleHistoryDetail']>, ParentType, ContextType, RequireFields<QueryGetScheduleHistoryArgs, 'date' | 'groupName' | 'lastKnownController' | 'tpID'>>;
  getTransporter?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType, RequireFields<QueryGetTransporterArgs, '_id'>>;
  getTSAPassengers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryGetTsaPassengersArgs, 'scheduledFlightDate' | 'tpID'>>;
  getUnassignedPassenger?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryGetUnassignedPassengerArgs, 'endCheckedInTime' | 'startCheckedInTime' | 'tpID'>>;
  GetUserOrganization?: Resolver<Maybe<ResolversTypes['OrganizationTypes']>, ParentType, ContextType>;
  getWhiteList?: Resolver<Maybe<ResolversTypes['WhiteList']>, ParentType, ContextType, RequireFields<QueryGetWhiteListArgs, '_id'>>;
  ISNQuickCheck?: Resolver<Maybe<ResolversTypes['IsnResultWithHeader']>, ParentType, ContextType, RequireFields<QueryIsnQuickCheckArgs, 'customerID' | 'isnNumber'>>;
  ListAvailableNotifiers?: Resolver<ResolversTypes['ListAvailableNotifiersResponse'], ParentType, ContextType, Partial<QueryListAvailableNotifiersArgs>>;
  ListScheduleHistory?: Resolver<Array<ResolversTypes['ScheduleHistoryListItem']>, ParentType, ContextType, RequireFields<QueryListScheduleHistoryArgs, 'endDate' | 'startDate' | 'tpID'>>;
  LiveCargo?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType, RequireFields<QueryLiveCargoArgs, 'tpID'>>;
  LiveFlight?: Resolver<Maybe<Array<Maybe<ResolversTypes['FlightNode']>>>, ParentType, ContextType, RequireFields<QueryLiveFlightArgs, 'tpID'>>;
  LivePassenger?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryLivePassengerArgs, 'tpID'>>;
  locations?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryLocationsArgs>>;
  MasterDataAircrafts?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataAircraftsArgs>>;
  MasterDataContracts?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataContractsArgs>>;
  MasterDataCustomers?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataCustomersArgs>>;
  MasterDataEmployers?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataEmployersArgs>>;
  MasterDataLocations?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataLocationsArgs>>;
  MasterDataPersonnel?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataPersonnelArgs>>;
  MasterDataPilots?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataPilotsArgs>>;
  MasterDataVessels?: Resolver<ResolversTypes['MangoQueryResult'], ParentType, ContextType, Partial<QueryMasterDataVesselsArgs>>;
  NodeDepartureList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType, RequireFields<QueryNodeDepartureListArgs, 'classType' | 'tpID'>>;
  NodeDestinationList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType, RequireFields<QueryNodeDestinationListArgs, 'classType' | 'tpID'>>;
  organizations?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryOrganizationsArgs>>;
  passengers?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryPassengersArgs>>;
  PaxCustomerList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Customer']>>>, ParentType, ContextType, RequireFields<QueryPaxCustomerListArgs, 'tpID'>>;
  PaxEmployerList?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employer']>>>, ParentType, ContextType, RequireFields<QueryPaxEmployerListArgs, 'tpID'>>;
  person?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<QueryPersonArgs, 'id'>>;
  personCredentials?: Resolver<Maybe<Array<Maybe<ResolversTypes['Credential']>>>, ParentType, ContextType, RequireFields<QueryPersonCredentialsArgs, 'personId'>>;
  persons?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryPersonsArgs>>;
  personSearch?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType, Partial<QueryPersonSearchArgs>>;
  pilots?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryPilotsArgs>>;
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType, RequireFields<QueryReportArgs, 'id'>>;
  reportCargo?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType, RequireFields<QueryReportCargoArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  reportFlights?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, RequireFields<QueryReportFlightsArgs, 'endDate' | 'finalized' | 'startDate' | 'tpID'>>;
  reportIsn?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryReportIsnArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  reportPassenger?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<QueryReportPassengerArgs, 'customerID' | 'endDate' | 'startDate' | 'tpID'>>;
  reports?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryReportsArgs>>;
  resolve_entity_ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['Entity']>>>, ParentType, ContextType, RequireFields<QueryResolve_Entity_IdsArgs, 'ids' | 'typename'>>;
  run_quick_report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType, RequireFields<QueryRun_Quick_ReportArgs, 'criteria' | 'name' | 'tpID' | 'type'>>;
  searchEmployers?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, RequireFields<QuerySearchEmployersArgs, 'query'>>;
  searchLocations?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, RequireFields<QuerySearchLocationsArgs, 'query'>>;
  UniqueListTest?: Resolver<Maybe<Array<Maybe<ResolversTypes['Customer']>>>, ParentType, ContextType, RequireFields<QueryUniqueListTestArgs, 'tpID'>>;
  vessels?: Resolver<Maybe<ResolversTypes['MangoQueryResult']>, ParentType, ContextType, Partial<QueryVesselsArgs>>;
};

export type CognitoUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CognitoUser'] = ResolversParentTypes['CognitoUser']> = {
  approvalStatus?: Resolver<Maybe<ResolversTypes['CognitoUserApprovalStatusEnum']>, ParentType, ContextType>;
  Attributes?: Resolver<Maybe<Array<ResolversTypes['CognitoUserAttribute']>>, ParentType, ContextType>;
  Enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['CognitoUserGroup']>>, ParentType, ContextType>;
  mappedAttributes?: Resolver<Maybe<ResolversTypes['DFCognitoUserMappedAtrributes']>, ParentType, ContextType>;
  UserCreateDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserLastModifiedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userSettings?: Resolver<ResolversTypes['WebUserSettings'], ParentType, ContextType>;
  UserStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CognitoUserAttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CognitoUserAttribute'] = ResolversParentTypes['CognitoUserAttribute']> = {
  Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CognitoUserGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CognitoUserGroup'] = ResolversParentTypes['CognitoUserGroup']> = {
  CreationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  GroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LastModifiedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DfCognitoUserMappedAtrributesResolvers<ContextType = any, ParentType extends ResolversParentTypes['DFCognitoUserMappedAtrributes'] = ResolversParentTypes['DFCognitoUserMappedAtrributes']> = {
  company_phone_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email_verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  family_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  given_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationClassType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_number_verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sub?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebUserSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebUserSettings'] = ResolversParentTypes['WebUserSettings']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  apps?: Resolver<Maybe<Array<ResolversTypes['WebUserAppSettings']>>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognitoUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractDocs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contract']>>>, ParentType, ContextType>;
  contracts?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Entity'] = ResolversParentTypes['Entity']> = {
  __resolveType: TypeResolveFn<'WebUserSettings' | 'Contract' | 'Location' | 'Rig' | 'Customer' | 'Employer' | 'Transporter' | 'Aircraft' | 'BoatNode' | 'CargoNode' | 'FlightNode' | 'Pilot' | 'PaxNode' | 'Credential' | 'Person' | 'Vessel' | 'DocksCargo' | 'Asset' | 'PobNode' | 'Dispatch' | 'HistoryFlightNode' | 'HistoryAircraft' | 'HistoryTransporter' | 'HistoryCargoNode' | 'HistoryContract' | 'HistoryLocation' | 'HistoryCustomer' | 'HistoryEmployer' | 'HistoryPilot' | 'HistoryPaxNode' | 'HistoryCredential' | 'HistoryPerson' | 'NonPreferred' | 'WhiteList' | 'Report' | 'DispatchHistory', ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
};

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export type WebUserAppSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebUserAppSettings'] = ResolversParentTypes['WebUserAppSettings']> = {
  appName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export type ContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  locationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  tpID?: Resolver<ResolversTypes['Transporter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationInterface'] = ResolversParentTypes['OrganizationInterface']> = {
  __resolveType: TypeResolveFn<'Organization' | 'Customer' | 'Employer' | 'Transporter' | 'HistoryTransporter' | 'HistoryOrganization' | 'HistoryCustomer' | 'HistoryEmployer', ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
};

export type ManifestPrintoutTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManifestPrintoutType'] = ResolversParentTypes['ManifestPrintoutType']> = {
  layouts?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutLayout']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManifestPrintoutLayoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManifestPrintoutLayout'] = ResolversParentTypes['ManifestPrintoutLayout']> = {
  logo?: Resolver<Maybe<ResolversTypes['AWSURL']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AwsurlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSURL'], any> {
  name: 'AWSURL';
}

export interface AwsDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDate'], any> {
  name: 'AWSDate';
}

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  areaBlockName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  deletedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  getRig?: Resolver<Maybe<ResolversTypes['Rig']>, ParentType, ContextType>;
  hasDataflytHardware?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOneOff?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerOrg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolvedCoordinates?: Resolver<Maybe<ResolversTypes['LocationCoordinates']>, ParentType, ContextType>;
  twicRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LocationType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RigResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rig'] = ResolversParentTypes['Rig']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  bedQuartersIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  bldgQuartersIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  deletedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  essentialPersonIdList?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  locationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  musterLastPrintedOnMap?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  musterLocNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  musterRequiresReprint?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  personIdToMusterStationMap?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  sectQuartersIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  askHaveYouBeenVaccinatedQuestion?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  ebsAirCanisterRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  employerEvaluationPercentage?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmployerEvaluationPercentage']>>>, ParentType, ContextType>;
  extraBroadCheckRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  illnessCheckRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationEmailList?: Resolver<Maybe<Array<ResolversTypes['OrgEmailListItem']>>, ParentType, ContextType>;
  quarantinedCheckRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  quarantinedCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  quarantineTravelPeriod?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  steelToeIDDestinations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployerEvaluationPercentageResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerEvaluationPercentage'] = ResolversParentTypes['EmployerEvaluationPercentage']> = {
  employerID?: Resolver<ResolversTypes['Employer'], ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employer'] = ResolversParentTypes['Employer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationEmailList?: Resolver<Maybe<Array<ResolversTypes['OrgEmailListItem']>>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgEmailListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgEmailListItem'] = ResolversParentTypes['OrgEmailListItem']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['OrgEmailListItemOptions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgEmailListItemOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrgEmailListItemOptions'] = ResolversParentTypes['OrgEmailListItemOptions']> = {
  optIn?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  optInAll?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransporterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transporter'] = ResolversParentTypes['Transporter']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationCoordinatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationCoordinates'] = ResolversParentTypes['LocationCoordinates']> = {
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminCognitoListUserGroupsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminCognitoListUserGroupsResult'] = ResolversParentTypes['AdminCognitoListUserGroupsResult']> = {
  groups?: Resolver<Array<ResolversTypes['CognitoUserGroup']>, ParentType, ContextType>;
  nextToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminCognitoListUsersResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminCognitoListUsersResult'] = ResolversParentTypes['AdminCognitoListUsersResult']> = {
  paginationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['CognitoUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MangoQueryResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MangoQueryResult'] = ResolversParentTypes['MangoQueryResult']> = {
  bookmark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  docs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Entity']>>>, ParentType, ContextType>;
  execution_stats?: Resolver<Maybe<ResolversTypes['MangoExecutionStats']>, ParentType, ContextType>;
  warning?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  warningCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MangoExecutionStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MangoExecutionStats'] = ResolversParentTypes['MangoExecutionStats']> = {
  execution_time_ms?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  results_returned?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_docs_examined?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_keys_examined?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_quorum_docs_examined?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AircraftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Aircraft'] = ResolversParentTypes['Aircraft']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  maxLoad?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxSeats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  tailNum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoatNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BoatNode'] = ResolversParentTypes['BoatNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  boatCrewIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['BoatCrew']>>>, ParentType, ContextType>;
  cgoIDList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  departureIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  desig?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Location']>>>, ParentType, ContextType>;
  dockState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  paxIDList?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  vesselID?: Resolver<Maybe<ResolversTypes['Vessel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BoatCrewResolvers<ContextType = any, ParentType extends ResolversParentTypes['BoatCrew'] = ResolversParentTypes['BoatCrew']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  authorization?: Resolver<Maybe<ResolversTypes['Authorization']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  isnNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastIsnResultWithHeader?: Resolver<Maybe<ResolversTypes['IsnResultWithHeader']>, ParentType, ContextType>;
  locationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthorizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Authorization'] = ResolversParentTypes['Authorization']> = {
  brdRestriction_EbsCard?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_Hazmat?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_HuetCard?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_Illness?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_Isn?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_NoFlyListed?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_NonPreferred?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_Quarantined?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_SafetyCard?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_SteelToes?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  brdRestriction_Twic?: Resolver<Maybe<ResolversTypes['BrdRestriction']>, ParentType, ContextType>;
  hasEbsCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasHuetCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasIllness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasQuarantined?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSafetyCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSteelToe?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasTwicReq?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  noFlyListed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  onBlackList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  onWhiteList?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  validIsnResult?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrdRestrictionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrdRestriction'] = ResolversParentTypes['BrdRestriction']> = {
  approverFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  approverLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overriderFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overriderLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overriderReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overriderTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redWhiteOverride?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  restrictionType?: Resolver<ResolversTypes['BrdRestrictionRestrictionType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BrdRestrictionStatus'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['BrdRestrictionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactInfo'] = ResolversParentTypes['ContactInfo']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['Name']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['Phone']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['Email'] = ResolversParentTypes['Email']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EmailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NameResolvers<ContextType = any, ParentType extends ResolversParentTypes['Name'] = ResolversParentTypes['Name']> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Phone'] = ResolversParentTypes['Phone']> = {
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PhoneType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IsnResultWithHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsnResultWithHeader'] = ResolversParentTypes['IsnResultWithHeader']> = {
  error_message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isn_customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isn_ownerid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isnResultType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qc_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  qc_result?: Resolver<Maybe<Array<Maybe<ResolversTypes['IsnQcResult']>>>, ParentType, ContextType>;
  quick_check_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectedResult?: Resolver<Maybe<ResolversTypes['IsnQcResult']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IsnQcResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsnQcResult'] = ResolversParentTypes['IsnQcResult']> = {
  allowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assignedActivities?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataAsOf?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isnId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isnResultType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isnStatus?: Resolver<Maybe<ResolversTypes['IsnStatus']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mostRecentTrainingExpirationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mostRecentTrainingQualificationExpirationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onlineTrainingQualificationProject?: Resolver<Maybe<ResolversTypes['IsnQcResultProject']>, ParentType, ContextType>;
  operatorQualificationProject?: Resolver<Maybe<ResolversTypes['IsnQcResultProject']>, ParentType, ContextType>;
  quickCheckStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reasonsForNonCompliance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scCompanyCurrentPoints?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scCompanyGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scCompanyGradeComponentsJson?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  scCompanyGradeSince?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scCompanyMaxPoints?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  scCompanyVariance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  trainingDeficiencies?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trainingQualificationProject?: Resolver<Maybe<ResolversTypes['IsnQcResultProject']>, ParentType, ContextType>;
  trainingStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IsnStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsnStatus'] = ResolversParentTypes['IsnStatus']> = {
  bgColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fgColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IsnQcResultProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsnQcResultProject'] = ResolversParentTypes['IsnQcResultProject']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  siteID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CargoNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CargoNode'] = ResolversParentTypes['CargoNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assetID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assignedLegSet?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  attentionTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageFlightTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cargoType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checkedInTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  checkInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  currentCarrierID?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  deliveredBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  firstCheckInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groundPickup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hazmat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hazmatUnCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualLegTime?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nodeState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notesForPilot?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivedLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  receivedLocationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scheduledVersion?: Resolver<Maybe<ResolversTypes['ScheduledVersion']>, ParentType, ContextType>;
  specialStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlightNode'] = ResolversParentTypes['FlightNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  aircraftID?: Resolver<Maybe<ResolversTypes['Aircraft']>, ParentType, ContextType>;
  callSign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cgoIDList?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  copilot?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  desig?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  estDepartureTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstLegUsableWt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  legs?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  legsArray?: Resolver<Maybe<Array<Maybe<ResolversTypes['FlightLeg']>>>, ParentType, ContextType>;
  liveEditedState?: Resolver<Maybe<ResolversTypes['LiveEditedState']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  originalFlightID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paxIDList?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType>;
  pilot?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduleType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeOfDeparture?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  tpID?: Resolver<ResolversTypes['Transporter'], ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PilotResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pilot'] = ResolversParentTypes['Pilot']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['Name']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['Phone']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlightLegResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlightLeg'] = ResolversParentTypes['FlightLeg']> = {
  bagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType>;
  cgoWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chosen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  departure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  flightTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType>;
  paxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  usefulLoad?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaxNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaxNode'] = ResolversParentTypes['PaxNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assignedLegSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorization?: Resolver<Maybe<ResolversTypes['Authorization']>, ParentType, ContextType>;
  averageFlightTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baggageIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  bagWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagWeightMap?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checkedInTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  checkInCred?: Resolver<Maybe<ResolversTypes['Credential']>, ParentType, ContextType>;
  checkInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  covid_bodyTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  covid_didPassBodyTempScan?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_ExposedToVirus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_hasBeenVaccinated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_hasFluSymptoms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_selfReportedHighBodyTemperature?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  currentCarrierID?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  employerID?: Resolver<Maybe<ResolversTypes['Employer']>, ParentType, ContextType>;
  firstCheckInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  getTzName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasCheckedSteelToesToday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  individualLegTime?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  isnCheckError?: Resolver<Maybe<ResolversTypes['IsnCheckError']>, ParentType, ContextType>;
  lastIsnResultWithHeader?: Resolver<Maybe<ResolversTypes['IsnResultWithHeader']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  nodeState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notesForPilot?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  personID?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scheduledVersion?: Resolver<Maybe<ResolversTypes['ScheduledVersion']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CredentialResolvers<ContextType = any, ParentType extends ResolversParentTypes['Credential'] = ResolversParentTypes['Credential']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CredentialType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  credentials?: Resolver<Maybe<Array<Maybe<ResolversTypes['Credential']>>>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  emergencyContactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employerID?: Resolver<Maybe<ResolversTypes['Employer']>, ParentType, ContextType>;
  essentialOn?: Resolver<Maybe<Array<ResolversTypes['Rig']>>, ParentType, ContextType>;
  extraBroadState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastBagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastBagWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastPaxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  nation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  python_backend_isNonPreferred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  vip?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IsnCheckErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsnCheckError'] = ResolversParentTypes['IsnCheckError']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VesselResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vessel'] = ResolversParentTypes['Vessel']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  transporter?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ViewResult'] = ResolversParentTypes['ViewResult']> = {
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ViewResultRow']>>>, ParentType, ContextType>;
  total_rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewResultRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['ViewResultRow'] = ResolversParentTypes['ViewResultRow']> = {
  doc?: Resolver<Maybe<ResolversTypes['Entity']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocksCargoResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocksCargo'] = ResolversParentTypes['DocksCargo']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  afeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assetID?: Resolver<Maybe<ResolversTypes['Asset']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assignedLegSet?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  attentionTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  belowDeck?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cargoType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checkedInTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  checkInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containerID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  currentCarrierID?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  deliveredBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  firstCheckInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groundPickup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hazmat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hazmatUnCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lifts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  liftWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  loe?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nodeState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offshoreCheckIn?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  offshoreCheckOut?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  ownerID?: Resolver<Maybe<ResolversTypes['Employer']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receivedLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  receivedLocationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  specialStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  deletedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerID?: Resolver<Maybe<ResolversTypes['Employer']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryCodes'] = ResolversParentTypes['CountryCodes']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countries?: Resolver<Maybe<Array<Maybe<ResolversTypes['Country']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  Code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PobNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PobNode'] = ResolversParentTypes['PobNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  assignedLegSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorization?: Resolver<Maybe<ResolversTypes['Authorization']>, ParentType, ContextType>;
  bagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baggageIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  bagWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagWeightMap?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checkedInTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  checkInCred?: Resolver<Maybe<ResolversTypes['Credential']>, ParentType, ContextType>;
  checkInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  currentCarrierID?: Resolver<Maybe<ResolversTypes['Carrier']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  employerID?: Resolver<Maybe<ResolversTypes['Employer']>, ParentType, ContextType>;
  firstCheckInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasCheckedSteelToesToday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isnCheckError?: Resolver<Maybe<ResolversTypes['IsnCheckError']>, ParentType, ContextType>;
  lastIsnResultWithHeader?: Resolver<Maybe<ResolversTypes['IsnResultWithHeader']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lifeBoatNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  nodeState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offshoreCheckIn?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  offshoreCheckOut?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  paxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  personID?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shiftTimes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShiftRecord']>>>, ParentType, ContextType>;
  tpID?: Resolver<ResolversTypes['Transporter'], ParentType, ContextType>;
  transitType?: Resolver<ResolversTypes['TransitType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarrierResolvers<ContextType = any, ParentType extends ResolversParentTypes['Carrier'] = ResolversParentTypes['Carrier']> = {
  __resolveType: TypeResolveFn<'BoatNode' | 'FlightNode', ParentType, ContextType>;
};

export type ShiftRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftRecord'] = ResolversParentTypes['ShiftRecord']> = {
  clockIn?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  clockOut?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  locationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DispatchResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dispatch'] = ResolversParentTypes['Dispatch']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _r_latestRemark?: Resolver<Maybe<ResolversTypes['DispatchRemark']>, ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  aircraftDoc?: Resolver<Maybe<ResolversTypes['Aircraft']>, ParentType, ContextType>;
  aircraftID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ato?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractDoc?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  coPilotDoc?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType>;
  coPilotID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  departingDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  departingID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  destinationDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  enRouteCheck?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fuel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fuelTaken?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  getTzAbbr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getTzName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastKnownControllerDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pilotDoc?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType>;
  pilotID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  pob?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  remark?: Resolver<Maybe<Array<ResolversTypes['DispatchRemark']>>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  specialsAckd?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DispatchStatus']>, ParentType, ContextType>;
  tpDoc?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DispatchRemarkResolvers<ContextType = any, ParentType extends ResolversParentTypes['DispatchRemark'] = ResolversParentTypes['DispatchRemark']> = {
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarkTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryFlightNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryFlightNode'] = ResolversParentTypes['HistoryFlightNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  aircraftID?: Resolver<Maybe<ResolversTypes['HistoryAircraft']>, ParentType, ContextType>;
  callSign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cgoIDList?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryCargoNode']>>>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['HistoryContract']>, ParentType, ContextType>;
  copilot?: Resolver<Maybe<ResolversTypes['HistoryPilot']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['HistoryCustomer']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  desig?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  firstLegUsableWt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  legs?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  legsArray?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryFlightLeg']>>>, ParentType, ContextType>;
  liveEditedState?: Resolver<Maybe<ResolversTypes['LiveEditedState']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  originalFlightID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paxIDList?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryPaxNode']>>>, ParentType, ContextType>;
  pilot?: Resolver<Maybe<ResolversTypes['HistoryPilot']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduleType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeOfDeparture?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  tpID?: Resolver<ResolversTypes['HistoryTransporter'], ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryAircraftResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryAircraft'] = ResolversParentTypes['HistoryAircraft']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  maxLoad?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxSeats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  tailNum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['HistoryTransporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryTransporterResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryTransporter'] = ResolversParentTypes['HistoryTransporter']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryCargoNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryCargoNode'] = ResolversParentTypes['HistoryCargoNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assetID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assignedLegSet?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  attentionTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageFlightTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cargoType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checkedInTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  checkInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['HistoryContract']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  currentCarrierID?: Resolver<Maybe<ResolversTypes['HistoryFlightNode']>, ParentType, ContextType>;
  customerDoc?: Resolver<Maybe<ResolversTypes['HistoryCustomer']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  deliveredBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departureDoc?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  destinationDoc?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  firstCheckInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groundPickup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hazmat?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hazmatUnCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  individualLegTime?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nodeState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivedLocation?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  receivedLocationID?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  specialStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['HistoryTransporter']>, ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryContract'] = ResolversParentTypes['HistoryContract']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<ResolversTypes['HistoryOrganization'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  locationID?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  tpID?: Resolver<ResolversTypes['HistoryTransporter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryOrganization'] = ResolversParentTypes['HistoryOrganization']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryLocation'] = ResolversParentTypes['HistoryLocation']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  block?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  deletedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerOrg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twicRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LocationType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryCustomer'] = ResolversParentTypes['HistoryCustomer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  ebsAirCanisterRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  employerEvaluationPercentage?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryEmployerEvaluationPercentage']>>>, ParentType, ContextType>;
  extraBroadCheckRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  illnessCheckRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarantinedCheckRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  quarantinedCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  quarantineTravelPeriod?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  steelToeIDDestinations?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryLocation']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryEmployerEvaluationPercentageResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryEmployerEvaluationPercentage'] = ResolversParentTypes['HistoryEmployerEvaluationPercentage']> = {
  employerID?: Resolver<ResolversTypes['HistoryEmployer'], ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryEmployerResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryEmployer'] = ResolversParentTypes['HistoryEmployer']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  isnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  licenseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifestPrintout?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManifestPrintoutType']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requireOverrideFields1?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  useV1Scheduler?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryPilotResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryPilot'] = ResolversParentTypes['HistoryPilot']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['Name']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['Phone']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['HistoryTransporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryFlightLegResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryFlightLeg'] = ResolversParentTypes['HistoryFlightLeg']> = {
  bagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryCargoNode']>>>, ParentType, ContextType>;
  cgoWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chosen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  departure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  flightTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryPaxNode']>>>, ParentType, ContextType>;
  paxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  usefulLoad?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryPaxNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryPaxNode'] = ResolversParentTypes['HistoryPaxNode']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  assignedLegSet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authorization?: Resolver<Maybe<ResolversTypes['Authorization']>, ParentType, ContextType>;
  averageFlightTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  baggageIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  bagWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bagWeightMap?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  chargeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkedIn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checkedInTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  checkInCred?: Resolver<Maybe<ResolversTypes['HistoryCredential']>, ParentType, ContextType>;
  checkInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['HistoryContract']>, ParentType, ContextType>;
  covid_bodyTemperature?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  covid_didPassBodyTempScan?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_ExposedToVirus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_hasBeenVaccinated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_hasFluSymptoms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  covid_selfReportedHighBodyTemperature?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  currentCarrierID?: Resolver<Maybe<ResolversTypes['HistoryFlightNode']>, ParentType, ContextType>;
  customerDoc?: Resolver<Maybe<ResolversTypes['HistoryCustomer']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  departureDoc?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  destinationDoc?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  employerDoc?: Resolver<Maybe<ResolversTypes['HistoryEmployer']>, ParentType, ContextType>;
  employerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  firstCheckInType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasCheckedSteelToesToday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  individualLegTime?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  isnCheckError?: Resolver<Maybe<ResolversTypes['IsnCheckError']>, ParentType, ContextType>;
  lastIsnResultWithHeader?: Resolver<Maybe<ResolversTypes['IsnResultWithHeader']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['HistoryLocation']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  nodeState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  personDoc?: Resolver<Maybe<ResolversTypes['HistoryPerson']>, ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['HistoryTransporter']>, ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryCredentialResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryCredential'] = ResolversParentTypes['HistoryCredential']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['HistoryPerson']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CredentialType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['HistoryPerson'] = ResolversParentTypes['HistoryPerson']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  credentials?: Resolver<Maybe<Array<Maybe<ResolversTypes['HistoryCredential']>>>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['HistoryCustomer']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  emergencyContactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  employeeNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employerID?: Resolver<Maybe<ResolversTypes['HistoryEmployer']>, ParentType, ContextType>;
  extraBroadState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  nation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vip?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonPreferredResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonPreferred'] = ResolversParentTypes['NonPreferred']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  organizationIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['NonPreferredOrganization']>>>, ParentType, ContextType>;
  personIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['NonPreferredPerson']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonPreferredOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonPreferredOrganization'] = ResolversParentTypes['NonPreferredOrganization']> = {
  endDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['OrganizationTypes']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationTypes'] = ResolversParentTypes['OrganizationTypes']> = {
  __resolveType: TypeResolveFn<'Customer' | 'Employer' | 'Transporter', ParentType, ContextType>;
};

export type NonPreferredPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonPreferredPerson'] = ResolversParentTypes['NonPreferredPerson']> = {
  endDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaxCgoResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaxCgoResult'] = ResolversParentTypes['ScheduledPaxCgoResult']> = {
  cgonodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CargoNode']>>>, ParentType, ContextType>;
  combined?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNodeCgoNodeUnion']>>>, ParentType, ContextType>;
  paxnodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaxNodeCgoNodeUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaxNodeCgoNodeUnion'] = ResolversParentTypes['PaxNodeCgoNodeUnion']> = {
  __resolveType: TypeResolveFn<'CargoNode' | 'PaxNode', ParentType, ContextType>;
};

export type PersonCov19VaxRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonCov19VaxRecord'] = ResolversParentTypes['PersonCov19VaxRecord']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employerID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  personID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  personName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PersonSetResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonSet'] = ResolversParentTypes['PersonSet']> = {
  _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  inbound?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType>;
  lastScheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outbound?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaxCgoGroupV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaxCgoGroupV2'] = ResolversParentTypes['ScheduledPaxCgoGroupV2']> = {
  cgoInbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoInboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoOutbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoOutboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoTransfer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoTransferOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  legCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  legs?: Resolver<Maybe<Array<ResolversTypes['ScheduledPaxCgoGroupV2Leg']>>, ParentType, ContextType>;
  paxInbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxInboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransfer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransferOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaxCgoGroupV2LegResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaxCgoGroupV2Leg'] = ResolversParentTypes['ScheduledPaxCgoGroupV2Leg']> = {
  cgonodeDocs?: Resolver<Array<ResolversTypes['CargoNode']>, ParentType, ContextType>;
  cgonodeIDs?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  departureDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  departureID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  destinationDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paxnodeDocs?: Resolver<Array<ResolversTypes['PaxNode']>, ParentType, ContextType>;
  paxnodeIDs?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  scheduledOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalBagCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalBagWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCgoWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPaxWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaxNodeGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaxNodeGroup'] = ResolversParentTypes['ScheduledPaxNodeGroup']> = {
  cgoInbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoInboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoOutbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoOutboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoTransfer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoTransferOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  paxInbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxInboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransfer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransferOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleHistoryDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleHistoryDetail'] = ResolversParentTypes['ScheduleHistoryDetail']> = {
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paxInboundCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxList?: Resolver<Array<ResolversTypes['PaxNode']>, ParentType, ContextType>;
  paxOutboundCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransferCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WhiteListResolvers<ContextType = any, ParentType extends ResolversParentTypes['WhiteList'] = ResolversParentTypes['WhiteList']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  locationToEmployer?: Resolver<Maybe<Array<Maybe<ResolversTypes['LocationToEmployerMap']>>>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  noncomplyPassList_CompanyGrade?: Resolver<Maybe<Array<ResolversTypes['WhiteListPersonToNonComplyBypassMap']>>, ParentType, ContextType>;
  noncomplyPassList_DrugAndAlcohol?: Resolver<Maybe<Array<ResolversTypes['WhiteListPersonToNonComplyBypassMap']>>, ParentType, ContextType>;
  noncomplyPassList_Training?: Resolver<Maybe<Array<ResolversTypes['WhiteListPersonToNonComplyBypassMap']>>, ParentType, ContextType>;
  organizationIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrganizationTypes']>>>, ParentType, ContextType>;
  personIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Person']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationToEmployerMapResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationToEmployerMap'] = ResolversParentTypes['LocationToEmployerMap']> = {
  employerIDs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employer']>>>, ParentType, ContextType>;
  locationID?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WhiteListPersonToNonComplyBypassMapResolvers<ContextType = any, ParentType extends ResolversParentTypes['WhiteListPersonToNonComplyBypassMap'] = ResolversParentTypes['WhiteListPersonToNonComplyBypassMap']> = {
  nonComplyBypass?: Resolver<Maybe<ResolversTypes['NonComplyBypass']>, ParentType, ContextType>;
  personID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonComplyBypassResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonComplyBypass'] = ResolversParentTypes['NonComplyBypass']> = {
  cognitoUser?: Resolver<Maybe<ResolversTypes['CognitoUserInfo']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  nonComplyCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CognitoUserInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CognitoUserInfo'] = ResolversParentTypes['CognitoUserInfo']> = {
  congitoUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  familyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListAvailableNotifiersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListAvailableNotifiersResponse'] = ResolversParentTypes['ListAvailableNotifiersResponse']> = {
  availableNotifierCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  availableNotifiers?: Resolver<Array<ResolversTypes['AvailableNotifier']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableNotifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvailableNotifier'] = ResolversParentTypes['AvailableNotifier']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  triggeringDataType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleHistoryListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleHistoryListItem'] = ResolversParentTypes['ScheduleHistoryListItem']> = {
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paxCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxInboundCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutboundCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransferCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  creationMetaData?: Resolver<Maybe<ResolversTypes['CreationMetaData']>, ParentType, ContextType>;
  criteria?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportCriteria']>>>, ParentType, ContextType>;
  endTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['ReportError']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['ReportResult']>, ParentType, ContextType>;
  startTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ReportStatus']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ReportType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreationMetaDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreationMetaData'] = ResolversParentTypes['CreationMetaData']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportCriteriaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportCriteria'] = ResolversParentTypes['ReportCriteria']> = {
  display_value?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportError'] = ResolversParentTypes['ReportError']> = {
  args?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  detailMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportResult'] = ResolversParentTypes['ReportResult']> = {
  docs?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  total_docs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_pages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addPassengersToFlight?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType, RequireFields<MutationAddPassengersToFlightArgs, 'flight' | 'paxIDs' | 'tpID'>>;
  addPaxAndCgoToFlight?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType, RequireFields<MutationAddPaxAndCgoToFlightArgs, 'cgoIDs' | 'flight' | 'paxIDs' | 'tpID'>>;
  AdminCognitoApproveUser?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<MutationAdminCognitoApproveUserArgs, 'Username'>>;
  AdminCognitoCreateUser?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType, RequireFields<MutationAdminCognitoCreateUserArgs, 'attributes' | 'username'>>;
  AdminCognitoDeleteUser?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<MutationAdminCognitoDeleteUserArgs, 'username'>>;
  AdminCognitoDenyUser?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<MutationAdminCognitoDenyUserArgs, 'Username'>>;
  AdminCognitoDisableUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationAdminCognitoDisableUserArgs, 'username'>>;
  AdminCognitoRevokeApproval?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<MutationAdminCognitoRevokeApprovalArgs, 'Username'>>;
  AdminCognitoUpdateUserAttributes?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<MutationAdminCognitoUpdateUserAttributesArgs, 'username'>>;
  AdminCognitoUpdateUserGroups?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, RequireFields<MutationAdminCognitoUpdateUserGroupsArgs, 'username'>>;
  AdminUpdateUserSettings?: Resolver<ResolversTypes['WebUserSettings'], ParentType, ContextType, RequireFields<MutationAdminUpdateUserSettingsArgs, 'cognitoUsername' | 'email'>>;
  ApproveDispatchFlightHistory?: Resolver<Maybe<ResolversTypes['ApproveDispatchFlightHistoryResponse']>, ParentType, ContextType, RequireFields<MutationApproveDispatchFlightHistoryArgs, 'aircraftID' | 'endTime' | 'startTime' | 'tpID' | 'tzAbbr'>>;
  boatCrewOverride?: Resolver<Maybe<ResolversTypes['BoatCrew']>, ParentType, ContextType, RequireFields<MutationBoatCrewOverrideArgs, '_id' | '_rev'>>;
  bulkDeletePersonCovid19VaxDate?: Resolver<Maybe<Array<ResolversTypes['PersonCov19VaxRecord']>>, ParentType, ContextType, RequireFields<MutationBulkDeletePersonCovid19VaxDateArgs, 'input'>>;
  bulkUpdatePersonCovid19VaxDate?: Resolver<Maybe<Array<ResolversTypes['PersonCov19VaxRecord']>>, ParentType, ContextType, RequireFields<MutationBulkUpdatePersonCovid19VaxDateArgs, 'input'>>;
  ChangePaxCheckInType?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationChangePaxCheckInTypeArgs, 'newCheckInType' | 'paxID'>>;
  CheckInPassengerDocks?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationCheckInPassengerDocksArgs, 'payload'>>;
  CheckInPassengerFlight?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationCheckInPassengerFlightArgs, 'payload'>>;
  CreateRigFromLoc?: Resolver<Maybe<ResolversTypes['Rig']>, ParentType, ContextType, RequireFields<MutationCreateRigFromLocArgs, 'locationID'>>;
  DeleteDispatchFlightHistory?: Resolver<Maybe<ResolversTypes['DeleteDispatchFlightHistoryResponse']>, ParentType, ContextType, RequireFields<MutationDeleteDispatchFlightHistoryArgs, 'tzAbbr'>>;
  deleteEmployers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employer']>>>, ParentType, ContextType, RequireFields<MutationDeleteEmployersArgs, 'id' | 'tpID'>>;
  deleteEntity?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType, RequireFields<MutationDeleteEntityArgs, '_id' | 'tpID'>>;
  deleteFlight?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType, RequireFields<MutationDeleteFlightArgs, '_id' | 'tpID'>>;
  DeleteFuelTaken?: Resolver<Maybe<ResolversTypes['FuelTaken']>, ParentType, ContextType, RequireFields<MutationDeleteFuelTakenArgs, 'fuelTaken' | 'tpID'>>;
  editFlightHistory?: Resolver<Maybe<ResolversTypes['HistoryFlightNode']>, ParentType, ContextType, RequireFields<MutationEditFlightHistoryArgs, 'flight' | 'flightID' | 'orgID' | 'times' | 'tpID'>>;
  export_data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationExport_DataArgs, 'column_sets' | 'content_type' | 'criteria' | 'data' | 'export_name' | 'file_type' | 'report_type'>>;
  export_report?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationExport_ReportArgs, 'column_sets' | 'content_type' | 'file_type' | 'report_id'>>;
  finalizeBoat?: Resolver<Maybe<ResolversTypes['BoatNode']>, ParentType, ContextType, RequireFields<MutationFinalizeBoatArgs, '_id' | 'tpID'>>;
  overridePassengerRestriction?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationOverridePassengerRestrictionArgs, '_id' | 'overrideType' | 'restrictionType' | 'tpID'>>;
  paxOverride?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationPaxOverrideArgs, '_id' | '_rev' | 'orgID'>>;
  removePassengersFromBoat?: Resolver<Maybe<ResolversTypes['BoatNode']>, ParentType, ContextType, RequireFields<MutationRemovePassengersFromBoatArgs, 'boatID' | 'paxIDs' | 'tpID'>>;
  revertFlightHistory?: Resolver<Maybe<ResolversTypes['HistoryFlightNode']>, ParentType, ContextType, RequireFields<MutationRevertFlightHistoryArgs, 'flightID'>>;
  run_report?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRun_ReportArgs, 'criteria' | 'name' | 'tpID' | 'type'>>;
  run_report_java?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRun_Report_JavaArgs, 'criteria' | 'name' | 'tpID' | 'type'>>;
  save_flight_times?: Resolver<Maybe<ResolversTypes['HistoryFlightNode']>, ParentType, ContextType, RequireFields<MutationSave_Flight_TimesArgs, 'flightID' | 'payload' | 'tpID'>>;
  schedulePassengers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PaxNode']>>>, ParentType, ContextType, RequireFields<MutationSchedulePassengersArgs, 'customerID' | 'departureID' | 'destinationID' | 'scheduledFlightDate' | 'scheduledGroup' | 'tpID'>>;
  SchedulePaxCgo_V2?: Resolver<Maybe<ResolversTypes['ScheduledPaxCgoResult']>, ParentType, ContextType, RequireFields<MutationSchedulePaxCgo_V2Args, 'customerID' | 'lastKnownController' | 'modifiedCgo' | 'modifiedPax' | 'scheduledFlightDate' | 'scheduledGroup' | 'tpID'>>;
  schedulePaxInbCgo?: Resolver<Maybe<ResolversTypes['ScheduledPaxCgoResult']>, ParentType, ContextType, RequireFields<MutationSchedulePaxInbCgoArgs, 'customerID' | 'departureID' | 'destinationID' | 'inboundCargo' | 'lastKnownController' | 'orgID' | 'passengers' | 'scheduledFlightDate' | 'scheduledGroup'>>;
  SendUserFeedback?: Resolver<ResolversTypes['AWSJSON'], ParentType, ContextType, RequireFields<MutationSendUserFeedbackArgs, 'appName' | 'responseType' | 'type'>>;
  setAircraft?: Resolver<Maybe<ResolversTypes['Aircraft']>, ParentType, ContextType, RequireFields<MutationSetAircraftArgs, 'maxLoad' | 'maxSeats' | 'model' | 'tailNum' | 'tpID'>>;
  setAircraftAustin?: Resolver<Maybe<ResolversTypes['Aircraft']>, ParentType, ContextType, RequireFields<MutationSetAircraftAustinArgs, 'maxLoad' | 'maxSeats' | 'model' | 'tailNum'>>;
  setBoatCrewIsnCheck?: Resolver<Maybe<ResolversTypes['BoatCrew']>, ParentType, ContextType, RequireFields<MutationSetBoatCrewIsnCheckArgs, '_id' | 'tpID'>>;
  setBoatCrewISNSelection?: Resolver<Maybe<ResolversTypes['BoatCrew']>, ParentType, ContextType, RequireFields<MutationSetBoatCrewIsnSelectionArgs, '_id' | '_rev' | 'payload'>>;
  setCargoNode?: Resolver<Maybe<ResolversTypes['CargoNode']>, ParentType, ContextType, Partial<MutationSetCargoNodeArgs>>;
  setContract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<MutationSetContractArgs, 'active' | 'customerID' | 'endDate' | 'name' | 'startDate' | 'tpID'>>;
  setCredential?: Resolver<Maybe<ResolversTypes['Credential']>, ParentType, ContextType, RequireFields<MutationSetCredentialArgs, 'tpID'>>;
  setCustomer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<MutationSetCustomerArgs, 'name' | 'steelToeIDDestinations' | 'tpID'>>;
  setEmployer?: Resolver<Maybe<ResolversTypes['Employer']>, ParentType, ContextType, RequireFields<MutationSetEmployerArgs, 'payload' | 'tpID'>>;
  SetEssenPerToRig?: Resolver<Maybe<ResolversTypes['Rig']>, ParentType, ContextType, RequireFields<MutationSetEssenPerToRigArgs, 'personID'>>;
  setFlight?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType, RequireFields<MutationSetFlightArgs, 'orgID' | 'payload'>>;
  setIsnSelection?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationSetIsnSelectionArgs, '_id' | '_rev' | 'payload'>>;
  setLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType, RequireFields<MutationSetLocationArgs, 'payload' | 'tpID'>>;
  setNonPreferred?: Resolver<Maybe<ResolversTypes['NonPreferred']>, ParentType, ContextType, RequireFields<MutationSetNonPreferredArgs, 'customerID' | 'tpID'>>;
  setPassengerIsnCheck?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationSetPassengerIsnCheckArgs, '_id' | 'tpID'>>;
  setPassengerNoFlyCheck?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, RequireFields<MutationSetPassengerNoFlyCheckArgs, '_id' | 'tpID' | 'value'>>;
  setPaxNode?: Resolver<Maybe<ResolversTypes['PaxNode']>, ParentType, ContextType, Partial<MutationSetPaxNodeArgs>>;
  setPerson?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType, RequireFields<MutationSetPersonArgs, 'orgID' | 'payload'>>;
  SetPersonOnWhiteList?: Resolver<Maybe<ResolversTypes['WhiteList']>, ParentType, ContextType, Partial<MutationSetPersonOnWhiteListArgs>>;
  setPersonSet?: Resolver<Maybe<ResolversTypes['PersonSet']>, ParentType, ContextType, RequireFields<MutationSetPersonSetArgs, 'payload'>>;
  setPilot?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType, RequireFields<MutationSetPilotArgs, 'firstName' | 'lastName' | 'tpID'>>;
  SetRemoteUserAppSettings?: Resolver<ResolversTypes['WebUserAppSettings'], ParentType, ContextType, RequireFields<MutationSetRemoteUserAppSettingsArgs, 'appName' | 'settings'>>;
  setScheduledToExpected?: Resolver<Maybe<ResolversTypes['FlightNode']>, ParentType, ContextType, RequireFields<MutationSetScheduledToExpectedArgs, 'flightID'>>;
  SetUserOrgNotificationEmailList?: Resolver<Maybe<ResolversTypes['OrganizationTypes']>, ParentType, ContextType, RequireFields<MutationSetUserOrgNotificationEmailListArgs, 'emailList'>>;
  setVessel?: Resolver<Maybe<ResolversTypes['Vessel']>, ParentType, ContextType, RequireFields<MutationSetVesselArgs, 'customerID' | 'name' | 'tpID'>>;
  setWhiteList?: Resolver<Maybe<ResolversTypes['WhiteList']>, ParentType, ContextType, RequireFields<MutationSetWhiteListArgs, 'customerID' | 'tpID'>>;
  UploadFuelTaken?: Resolver<Maybe<ResolversTypes['FuelTaken']>, ParentType, ContextType, RequireFields<MutationUploadFuelTakenArgs, 'fuelTaken' | 'tpID'>>;
};

export type ApproveDispatchFlightHistoryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApproveDispatchFlightHistoryResponse'] = ResolversParentTypes['ApproveDispatchFlightHistoryResponse']> = {
  changedOrAdded?: Resolver<Maybe<Array<ResolversTypes['DispatchHistory']>>, ParentType, ContextType>;
  deletedIDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DispatchHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DispatchHistory'] = ResolversParentTypes['DispatchHistory']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _r_latestRemark?: Resolver<Maybe<ResolversTypes['DispatchRemark']>, ParentType, ContextType>;
  _rev?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  aircraftDoc?: Resolver<Maybe<ResolversTypes['Aircraft']>, ParentType, ContextType>;
  aircraftID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ato?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractDoc?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  contractID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  coPilotDoc?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType>;
  coPilotID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  departingDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  departingID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  destinationDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  dispatchDoc?: Resolver<Maybe<ResolversTypes['Dispatch']>, ParentType, ContextType>;
  enRouteCheck?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fuel?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fuelTaken?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  getTzAbbr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getTzName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastKnownControllerDoc?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pilotDoc?: Resolver<Maybe<ResolversTypes['Pilot']>, ParentType, ContextType>;
  pilotID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  pob?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  remark?: Resolver<Maybe<Array<ResolversTypes['DispatchRemark']>>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  specialsAckd?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DispatchStatus']>, ParentType, ContextType>;
  tpDoc?: Resolver<Maybe<ResolversTypes['Transporter']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDispatchFlightHistoryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteDispatchFlightHistoryResponse'] = ResolversParentTypes['DeleteDispatchFlightHistoryResponse']> = {
  deletedIDs?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FuelTakenResolvers<ContextType = any, ParentType extends ResolversParentTypes['FuelTaken'] = ResolversParentTypes['FuelTaken']> = {
  aircraftId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contractId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  dateTimeTaken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flightIdName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  psid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsPhoneScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSPhone'], any> {
  name: 'AWSPhone';
}

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  updatePax?: SubscriptionResolver<Maybe<ResolversTypes['PaxNode']>, "updatePax", ParentType, ContextType>;
};

export interface AwsipAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSIPAddress'], any> {
  name: 'AWSIPAddress';
}

export interface AwsTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTime'], any> {
  name: 'AWSTime';
}

export interface AwsTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export type BulkDocsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkDocsResult'] = ResolversParentTypes['BulkDocsResult']> = {
  __resolveType: TypeResolveFn<'SchedulePersonsResult', ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rev?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type RequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['Request'] = ResolversParentTypes['Request']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  _rev?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedTs?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['Person']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CredentialType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledPaxCgoGroupV1Resolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledPaxCgoGroupV1'] = ResolversParentTypes['ScheduledPaxCgoGroupV1']> = {
  cgoInbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoInboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoOutbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoOutboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoTransfer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cgoTransferOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customerID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  departureID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  destinationID?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastKnownController?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  paxInbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxInboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutbound?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxOutboundOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransfer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paxTransferOnFlight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scheduledFlightDate?: Resolver<Maybe<ResolversTypes['AWSDate']>, ParentType, ContextType>;
  scheduledGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tpID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchedulePersonsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SchedulePersonsResult'] = ResolversParentTypes['SchedulePersonsResult']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rev?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transitType?: Resolver<Maybe<ResolversTypes['TransitType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sections?: Resolver<Maybe<Array<Maybe<ResolversTypes['Section']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkyRouterFlightTrackRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['SkyRouterFlightTrackRow'] = ResolversParentTypes['SkyRouterFlightTrackRow']> = {
  altitide?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dateAquisDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateAquisTIme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  DOP?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heading?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  imeiNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiverStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reportType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sysDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sysTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  velocity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type XxxCouchDbInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['xxxCouchDbInfo'] = ResolversParentTypes['xxxCouchDbInfo']> = {
  db_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type XxxLocationRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['xxxLocationRow'] = ResolversParentTypes['xxxLocationRow']> = {
  key?: Resolver<Array<Maybe<ResolversTypes['AWSJSON']>>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type XxxLocationRowsResolvers<ContextType = any, ParentType extends ResolversParentTypes['xxxLocationRows'] = ResolversParentTypes['xxxLocationRows']> = {
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['xxxLocationRow']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Query?: QueryResolvers<ContextType>;
  CognitoUser?: CognitoUserResolvers<ContextType>;
  CognitoUserAttribute?: CognitoUserAttributeResolvers<ContextType>;
  CognitoUserGroup?: CognitoUserGroupResolvers<ContextType>;
  DFCognitoUserMappedAtrributes?: DfCognitoUserMappedAtrributesResolvers<ContextType>;
  WebUserSettings?: WebUserSettingsResolvers<ContextType>;
  Entity?: EntityResolvers<ContextType>;
  AWSDateTime?: GraphQLScalarType;
  WebUserAppSettings?: WebUserAppSettingsResolvers<ContextType>;
  AWSJSON?: GraphQLScalarType;
  Contract?: ContractResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationInterface?: OrganizationInterfaceResolvers<ContextType>;
  ManifestPrintoutType?: ManifestPrintoutTypeResolvers<ContextType>;
  ManifestPrintoutLayout?: ManifestPrintoutLayoutResolvers<ContextType>;
  AWSURL?: GraphQLScalarType;
  AWSDate?: GraphQLScalarType;
  Location?: LocationResolvers<ContextType>;
  Rig?: RigResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  EmployerEvaluationPercentage?: EmployerEvaluationPercentageResolvers<ContextType>;
  Employer?: EmployerResolvers<ContextType>;
  OrgEmailListItem?: OrgEmailListItemResolvers<ContextType>;
  OrgEmailListItemOptions?: OrgEmailListItemOptionsResolvers<ContextType>;
  Transporter?: TransporterResolvers<ContextType>;
  LocationCoordinates?: LocationCoordinatesResolvers<ContextType>;
  AdminCognitoListUserGroupsResult?: AdminCognitoListUserGroupsResultResolvers<ContextType>;
  AdminCognitoListUsersResult?: AdminCognitoListUsersResultResolvers<ContextType>;
  MangoQueryResult?: MangoQueryResultResolvers<ContextType>;
  MangoExecutionStats?: MangoExecutionStatsResolvers<ContextType>;
  Aircraft?: AircraftResolvers<ContextType>;
  BoatNode?: BoatNodeResolvers<ContextType>;
  BoatCrew?: BoatCrewResolvers<ContextType>;
  Authorization?: AuthorizationResolvers<ContextType>;
  BrdRestriction?: BrdRestrictionResolvers<ContextType>;
  ContactInfo?: ContactInfoResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  Email?: EmailResolvers<ContextType>;
  Name?: NameResolvers<ContextType>;
  Phone?: PhoneResolvers<ContextType>;
  IsnResultWithHeader?: IsnResultWithHeaderResolvers<ContextType>;
  IsnQcResult?: IsnQcResultResolvers<ContextType>;
  IsnStatus?: IsnStatusResolvers<ContextType>;
  IsnQcResultProject?: IsnQcResultProjectResolvers<ContextType>;
  CargoNode?: CargoNodeResolvers<ContextType>;
  FlightNode?: FlightNodeResolvers<ContextType>;
  Pilot?: PilotResolvers<ContextType>;
  FlightLeg?: FlightLegResolvers<ContextType>;
  PaxNode?: PaxNodeResolvers<ContextType>;
  Credential?: CredentialResolvers<ContextType>;
  Person?: PersonResolvers<ContextType>;
  IsnCheckError?: IsnCheckErrorResolvers<ContextType>;
  Vessel?: VesselResolvers<ContextType>;
  ViewResult?: ViewResultResolvers<ContextType>;
  ViewResultRow?: ViewResultRowResolvers<ContextType>;
  DocksCargo?: DocksCargoResolvers<ContextType>;
  Asset?: AssetResolvers<ContextType>;
  CountryCodes?: CountryCodesResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  PobNode?: PobNodeResolvers<ContextType>;
  Carrier?: CarrierResolvers<ContextType>;
  ShiftRecord?: ShiftRecordResolvers<ContextType>;
  Dispatch?: DispatchResolvers<ContextType>;
  DispatchRemark?: DispatchRemarkResolvers<ContextType>;
  HistoryFlightNode?: HistoryFlightNodeResolvers<ContextType>;
  HistoryAircraft?: HistoryAircraftResolvers<ContextType>;
  HistoryTransporter?: HistoryTransporterResolvers<ContextType>;
  HistoryCargoNode?: HistoryCargoNodeResolvers<ContextType>;
  HistoryContract?: HistoryContractResolvers<ContextType>;
  HistoryOrganization?: HistoryOrganizationResolvers<ContextType>;
  HistoryLocation?: HistoryLocationResolvers<ContextType>;
  HistoryCustomer?: HistoryCustomerResolvers<ContextType>;
  HistoryEmployerEvaluationPercentage?: HistoryEmployerEvaluationPercentageResolvers<ContextType>;
  HistoryEmployer?: HistoryEmployerResolvers<ContextType>;
  HistoryPilot?: HistoryPilotResolvers<ContextType>;
  HistoryFlightLeg?: HistoryFlightLegResolvers<ContextType>;
  HistoryPaxNode?: HistoryPaxNodeResolvers<ContextType>;
  HistoryCredential?: HistoryCredentialResolvers<ContextType>;
  HistoryPerson?: HistoryPersonResolvers<ContextType>;
  NonPreferred?: NonPreferredResolvers<ContextType>;
  NonPreferredOrganization?: NonPreferredOrganizationResolvers<ContextType>;
  OrganizationTypes?: OrganizationTypesResolvers<ContextType>;
  NonPreferredPerson?: NonPreferredPersonResolvers<ContextType>;
  ScheduledPaxCgoResult?: ScheduledPaxCgoResultResolvers<ContextType>;
  PaxNodeCgoNodeUnion?: PaxNodeCgoNodeUnionResolvers<ContextType>;
  PersonCov19VaxRecord?: PersonCov19VaxRecordResolvers<ContextType>;
  PersonSet?: PersonSetResolvers<ContextType>;
  ScheduledPaxCgoGroupV2?: ScheduledPaxCgoGroupV2Resolvers<ContextType>;
  ScheduledPaxCgoGroupV2Leg?: ScheduledPaxCgoGroupV2LegResolvers<ContextType>;
  ScheduledPaxNodeGroup?: ScheduledPaxNodeGroupResolvers<ContextType>;
  ScheduleHistoryDetail?: ScheduleHistoryDetailResolvers<ContextType>;
  WhiteList?: WhiteListResolvers<ContextType>;
  LocationToEmployerMap?: LocationToEmployerMapResolvers<ContextType>;
  WhiteListPersonToNonComplyBypassMap?: WhiteListPersonToNonComplyBypassMapResolvers<ContextType>;
  NonComplyBypass?: NonComplyBypassResolvers<ContextType>;
  CognitoUserInfo?: CognitoUserInfoResolvers<ContextType>;
  ListAvailableNotifiersResponse?: ListAvailableNotifiersResponseResolvers<ContextType>;
  AvailableNotifier?: AvailableNotifierResolvers<ContextType>;
  ScheduleHistoryListItem?: ScheduleHistoryListItemResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  CreationMetaData?: CreationMetaDataResolvers<ContextType>;
  ReportCriteria?: ReportCriteriaResolvers<ContextType>;
  ReportError?: ReportErrorResolvers<ContextType>;
  ReportResult?: ReportResultResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  ApproveDispatchFlightHistoryResponse?: ApproveDispatchFlightHistoryResponseResolvers<ContextType>;
  DispatchHistory?: DispatchHistoryResolvers<ContextType>;
  DeleteDispatchFlightHistoryResponse?: DeleteDispatchFlightHistoryResponseResolvers<ContextType>;
  FuelTaken?: FuelTakenResolvers<ContextType>;
  AWSEmail?: GraphQLScalarType;
  AWSPhone?: GraphQLScalarType;
  Subscription?: SubscriptionResolvers<ContextType>;
  AWSIPAddress?: GraphQLScalarType;
  AWSTime?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  BulkDocsResult?: BulkDocsResultResolvers<ContextType>;
  Request?: RequestResolvers<ContextType>;
  ScheduledPaxCgoGroupV1?: ScheduledPaxCgoGroupV1Resolvers<ContextType>;
  SchedulePersonsResult?: SchedulePersonsResultResolvers<ContextType>;
  Section?: SectionResolvers<ContextType>;
  SkyRouterFlightTrackRow?: SkyRouterFlightTrackRowResolvers<ContextType>;
  xxxCouchDbInfo?: XxxCouchDbInfoResolvers<ContextType>;
  xxxLocationRow?: XxxLocationRowResolvers<ContextType>;
  xxxLocationRows?: XxxLocationRowsResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_publish?: Aws_PublishDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
  defer?: DeferDirectiveResolver<any, any, ContextType>;
};
